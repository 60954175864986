import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {$} from 'jquery';
import {SharedService} from '../service/shared.service';

@Component({
    selector: 'app-myservices',
    templateUrl: './myservices.component.html',
    styleUrls: ['./myservices.component.css']
})
export class MyservicesComponent implements OnInit {
    tokenvalue: any;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    branddata: any[];
    servicedata: any[];
    alldatavalue: any[];
    useraccess: any = {
        rl_id: ''
    };
    serviceslug: string;
    gb3: any;
    gb4: any;
    Dräger: string;
    news: string;
    content: any;
    news2: boolean;
    newsdata1: any;
    newsdata: string;


    constructor(private authservice: AuthService, private router: Router, private httpclient: HttpClient, private shared: SharedService) {

    }

    ngOnInit() {
        if (localStorage.getItem('auth-token') == null) {
            this.router.navigate(['/login'])
        } else {
            this.tokenvalue = localStorage.getItem('auth-token');
            this.myservices(this.tokenvalue);
        }

    }

    myservices(token) {
        this.httpclient.get(this.api + "auth/myservice?token=" + token).subscribe((data: servicedata) => {
            this.servicedata = data.my_service;
            console.log(typeof this.servicedata);


            var res = [];

            for (var x in this.servicedata) {
                res.push(this.servicedata[x].name)
            }


            if (res.includes('Dräger')) {
                if (res.length == 1) {
                    this.content = "only drager";
                } else {
                    this.content = 'drager';
                }
            } else {
                this.content = 'no drager';
            }

            this.shared.getBrandName(this.content);

            // localStorage.setItem('servicedata',JSON.stringify( this.servicedata ))
        }, (error) => {
            console.log(error);
        });


    }

    change_fout(id) {
        this.tokenvalue = localStorage.getItem('auth-token');
        localStorage.setItem('library', id)
        this.httpclient.get(this.api + "auth/fout_insert?token=" + this.tokenvalue + "&slug=" + id).subscribe((data: foutdata) => {
            this.router.navigate(['/library/' + id]);
        }, (error) => {
            console.log(error);
        })
    }

    redirect_service(slug, enforcevalue) {

        this.shared.serviceSlug(slug)
        this.httpclient.get(this.api + 'auth/access_rights?token=' + this.tokenvalue + "&slug=" + slug).subscribe((data: userroalaccess) => {
            let user_access = data.role;
            this.useraccess.rl_id = user_access['0'].rl_id         //program like tutor student and all
            localStorage.setItem('user_role', user_access['0'].rl_id)
            this.mainRedirect(slug, enforcevalue);
        })
    }


    mainRedirect(slug, enforcevalue) {
        this.tokenvalue = localStorage.getItem('auth-token');

        if (enforcevalue == 1) {
            if (this.useraccess.rl_id == '1') {
                localStorage.setItem('service_enforce', enforcevalue)
            } else {
                localStorage.setItem('service_enforce', '0')
            }
        } else {
            localStorage.setItem('service_enforce', '0')
        }

        this.httpclient.get(this.api + "auth/modulemiddlewarelisting?token=" + this.tokenvalue + "&slug=" + slug).subscribe((data: alldatavalue_modules) => {
            this.alldatavalue = data.redirect;
            localStorage.setItem('redirect', data.redirect)

            let check = data.redirect.substr(0, data.redirect.indexOf('/'))
            if (check == 'livebook') {
                if (data.modules.length == 1) {
                    localStorage.setItem('modulestatus', data.modules['0'].module_status)
                    localStorage.setItem('one_module', '1');
                    localStorage.setItem('link', data.redirect)
                    this.shared.serviceDoneFastFlow(data.redirect)
                } else {
                    localStorage.setItem('one_module', '0');
                    localStorage.removeItem('link')
                    this.shared.serviceDoneFastFlow(data.redirect)
                }
            }
            if (check == 'library') {
                if (data.modules.length == 1) {
                    localStorage.setItem('modulestatus', data.modules['0'].module_status)
                    localStorage.setItem('one_module', '1');
                    localStorage.setItem('link', data.redirect)
                    this.shared.serviceDoneFastFlow(data.redirect)
                } else {
                    localStorage.setItem('one_module', '0');
                    localStorage.removeItem('link')
                    this.shared.serviceDoneFastFlow(data.redirect)
                }
            }
            if (check == 'design-connect') {
                localStorage.setItem('one_module', '0');
                localStorage.removeItem('link')
                this.shared.serviceDoneFastFlow(data.redirect)

            }


            this.router.navigate([this.alldatavalue]);
        });
    }


}

export interface alldatavalue_modules {
    redirect: any;
    modules: any[];
}

export interface servicedata {
    my_service: any[];
}

export interface foutdata {
    status: any[];
}

export interface userroalaccess {
    role: any;
}