import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {$} from 'jquery';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    tokenvalue: string;
    profile_datavalue: any = [];
    linkedin: any;
    company_name: any = '';
    mobile: any = '';

    constructor(private authservice: AuthService, private router: Router, private httpclient: HttpClient, private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.authservice.hideshowheader(false);
        this.tokenvalue = localStorage.getItem('auth-token');

        this.getprofiledetails(this.tokenvalue);
    }

    getprofiledetails(tokenvalue) {
        this.httpclient.get(this.api + "auth/profiledata?token=" + tokenvalue).subscribe((data: profile) => {
            console.log(data)
            this.profile_datavalue = data.profile;
            //check linkid link
            if (this.profile_datavalue['linkdin']) {
                let url: string = '';
                if (!/^http[s]?:\/\//.test(this.profile_datavalue['linkdin'])) {
                    url += 'https://';
                }
                this.linkedin = url + this.profile_datavalue['linkdin'];
            }
            //check company name
            if (this.profile_datavalue['company_name']) {
                if (this.profile_datavalue['company_name'] != 'none') {
                    this.company_name = this.profile_datavalue['company_name'];
                }
            }
            //check mobile
            if (this.profile_datavalue['mobile']) {
                if (this.profile_datavalue['mobile'] != '' && this.profile_datavalue['mobile'] != 'null') {
                    this.mobile = this.profile_datavalue['mobile'];
                }
            }
        }, (error) => {
            console.log(error);
        })
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}

export interface profile {
    profile: any[];
}
