import {Component, OnInit, ViewChild} from '@angular/core';
import {
    SwiperComponent, SwiperDirective, SwiperConfigInterface,
    SwiperScrollbarInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import {AuthService} from '../service/auth.service';

@Component({
    selector: 'app-coty',
    templateUrl: './coty.component.html',
    styleUrls: ['./coty.component.css']
})
export class CotyComponent implements OnInit {

    constructor(public authservice: AuthService) {
    }

    @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;
    config: SwiperConfigInterface = {};

    ngOnInit() {

        var menu = ['1', '2', '3', '4', '5']
        this.config = {
            /**used angular2-usefull-swiper for mobile**/
            a11y: true,
            direction: 'horizontal',
            slidesPerView: 1,
            keyboard: true,
            mousewheel: false,
            scrollbar: false,
            navigation: false,
            pagination: {
                el: '.cpage',
                clickable: true,
                hideOnClick: false,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (menu[index]) + '</span>';
                },
            }

        };
        this.authservice.hideshowheader(true);
    }

}
