import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth.service';
import {HttpClient} from '@angular/common/http';
import {SharedService} from '../service/shared.service';
import {environment} from 'src/environments/environment';


@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css', './../note-summary/note-summary.component.css']
})
export class MessagesComponent implements OnInit {
    token: string;
    serviceslug: string;
    message_data: any;
    service_name: string;
    user_slug: string;
    single_user_data: any;
    image: any;
    imgurl = environment.imageurl;
    fk_brand_id: string;

    constructor(private authservice: AuthService, private httpclient: HttpClient, private shareservice: SharedService) {
        this.service_name = localStorage.getItem('service_name');
        this.token = localStorage.getItem('auth-token');
        this.serviceslug = localStorage.getItem('library');
        this.user_slug = localStorage.getItem('user_slug')
    }

    ngOnInit() {
        this.fk_brand_id = localStorage.getItem("fk_brand_id");

        this.authservice.hideshowheader(false);
        this.messsageCount()
        this.getMessages()
        this.profiledata(this.token, this.user_slug)
    }

    getMessages() {
        this.httpclient.get(this.shareservice.api + 'auth/view_message?token=' + this.token + "&service_slug=" + this.serviceslug).subscribe((data: message_interface) => {
            console.log(data)
            this.message_data = data.data
            this.shareservice.messageNotification(data.not_readcount)
        }),
            (error) => {
                console.log(error)
            }
    }

    profiledata(token, slug) {
        let libraryvalue = localStorage.getItem('library');
        this.httpclient.get(this.shareservice.api + "auth/profilepage?token=" + token + "&slug=" + slug + "&service_slug=" + libraryvalue).subscribe((data: singleuser) => {
            this.single_user_data = data.profiledata;


            this.image = this.single_user_data['actual_image'];      //overview_image


        }, (error) => {
            console.log(error);
        })
    }

    //to send data to backend about the message notification is read or not
    messsageCount() {
        this.httpclient.post(this.shareservice.api + "auth/insert_meassage_status?token=" + this.token + "&service_slug=" + this.serviceslug, "").subscribe((data) => {
            console.log(data)
        })
    }
}


export interface message_interface {
    data: any;
    not_readcount: any;
}

export interface singleuser {
    profiledata: any[];
}