import {Component, OnInit} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BreakpointObserver} from '@angular/cdk/layout';
import $ from 'jquery';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'grow';
    ismobile: any;
    isIPhone: any;

    constructor(private deviceService: DeviceDetectorService, public breakpointObserver: BreakpointObserver) {

    }

    ngOnInit() {
        this.breakpointObserver
            .observe(['(min-width:1024px) and (max-width:1920px)'])
            .subscribe((state) => {
                if (state.matches) {
                    $('body').css('max-width', '1920px');
                    $('body').css('min-width', '');
                    $('body').css('margin', '0px auto');
                    $('body').css('width', '');
                    $('body').css('overflow-y', '');

                } else {
                    $('body').css('max-width', '1920px');
                    $('body').css('min-width', '');
                    $('body').css('margin', '0px auto');
                    $('body').css('width', '');
                    $('body').css('overflow-y', '');

                }
            });
        this.breakpointObserver
            .observe(['(min-width:768px) and (max-width:1023px)'])
            .subscribe((state) => {
                if (state.matches) {
                    $('body').css('max-width', '');
                    $('body').css('min-width', '');
                    $('body').css('margin', '');
                    $('body').css('width', '');
                    $('body').css('overflow-y', 'scroll');

                } else {


                }
            });
        this.ismobile = this.deviceService.isMobile();
        this.isIPhone = this.deviceService.device === 'iPhone';
    }


}
