import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from "rxjs/operators";
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    api = environment.apiEndpoint

    constructor(private httpclient: HttpClient) {
    }

    peoplePage = new BehaviorSubject('People')

    peoplePageProAss(v) {
        this.peoplePage.next(v)
    }

    topbarpeople = new BehaviorSubject('')

    topbarPeopleValue(v) {
        this.topbarpeople.next(v)
    }

    modulename = new BehaviorSubject('')

    getModuleName(v) {
        this.modulename.next(v)
    }

    servicename = new BehaviorSubject('')

    getServiceName(v) {
        this.servicename.next(v)
    }

    brandname = new BehaviorSubject('')

    getBrandName(servicedata) {
        this.brandname.next(servicedata);
    }


    //user roles and module name and chapter name
    getRolesModuleChapterName(token, modulid, chapterid) {
        return this.httpclient.get(this.api + "auth/role_breadcrumb?token=" + token + "&module=" + modulid + "&chapter=" + chapterid).pipe(map((data) => {
            let roledata = data;
            return roledata;
        }))
    }

    message_count = new BehaviorSubject(0)

    messageNotification(v) {
        this.message_count.next(v)
    }

    enlarge_image = new BehaviorSubject(null)

    enLargeImagePath(v) {
        this.enlarge_image.next(v)
        console.log(v)
    }

    servicedone = new BehaviorSubject(null)

    serviceDoneFastFlow(v) {
        this.servicedone.next(v)
    }

    service_slug = new BehaviorSubject(null)

    serviceSlug(v) {
        this.service_slug.next(v)
    }

    change_profile_image = new BehaviorSubject(false)

    changeProfileImage(v) {
        this.change_profile_image.next(v)
    }

    module_status = new BehaviorSubject(1)

    moduleStatusValue(v) {
        this.module_status.next(v)
    }

    /**for image**/
    dataURItoBlob(dataURI) {
        var binary = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var array = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: mimeString});
    };

    /**end for image**/

}


export interface roleModuleChapterNameInterface {
    pageoverview_name: any;
    roleid: any,
    rolename: any,
    module_name: any,
    chapter_name: any,
}

export interface user_accessInterface {
    isaddnote: any;
    isaddreflection: any;
    islivebookserviceaccess: any;
    isprofnotesshowlivebook: any;
    isprogram: any;
    isroleid: any;
    isuploadassignments: any;
    isworkshop: any;
    view_notes: any
}