import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {CanActivate} from '@angular/router';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthguardService {

    constructor(private authservice: AuthService, private router: Router) {
    }


    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.authservice.isLoggedIn()) {
            return true;
        } else {
            this.router.navigate(['/login'], {queryParams: {'redirectURL': state.url}})
            return false;
        }
    }
}
