import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from '../service/auth.service';
import {$} from 'jquery';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    forgot: FormGroup;
    forgot_password: boolean = false;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    message: any[];
    messagevalue: any[];

    constructor(private fb: FormBuilder, public authservice: AuthService, private httpclient: HttpClient) {
    }

    ngOnInit() {
        this.forgot = this.fb.group({
            'email': ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]]
        })

        this.authservice.hideshowheader(false);
    }

    forgotPassword(data) {
        if (this.forgot.valid == true && !this.forgot_password) {
            this.forgot_password = true;

            this.httpclient.post(this.api + "auth/recovery", data).subscribe((data: sendatata) => {
                this.message = data.message;
            }, (error) => {
                console.log(error);
            })
        }
    }

}

export interface sendatata {
    message: any[];
}
