import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from '../service/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {$} from 'jquery';

@Component({
    selector: 'app-setpassword',
    templateUrl: './setpassword.component.html',
    styleUrls: ['./setpassword.component.css']
})
export class SetpasswordComponent implements OnInit {

    reset: FormGroup;
    reset_password: boolean = false;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    passwordtoken: any;
    alldata: any;
    message: string;
    statusvalues: boolean;

    constructor(private router: Router, private fb: FormBuilder, private ar: ActivatedRoute, public authservice: AuthService, private httpclient: HttpClient) {
    }

    ngOnInit() {

        this.ar.params.subscribe((params) => {
            this.passwordtoken = params['ps_token'];
            this.checkorredirect(this.passwordtoken);
        });

        this.reset = this.fb.group({
            'email': ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            'password': ['', [Validators.required]],
            'password_reset_token': ['', [Validators.required]]
        });
        this.authservice.hideshowheader(false);
    }

    resetPassword(data) {
        this.reset_password = true;
        if (this.reset.valid) {
            this.httpclient.post(this.api + "auth/reset", data).subscribe((data: resetvalues) => {
                this.reset.controls['email'].setValue('');
                this.reset.controls['password_reset_token'].setValue('');

                this.router.navigate(['/login'])
            }, (error) => {
                console.log(error);
            })
        }
    }

    checkorredirect(token) {
        this.httpclient.get(this.api + "auth/passwordtoken?pass_token=" + token).subscribe((data: checktoken) => {
            this.alldata = data.email_id;
            this.message = this.alldata == '' ? "The token has expired." : '';

            this.reset.controls['email'].setValue(this.alldata);
            this.reset.controls['password_reset_token'].setValue(token);

        }, (error) => {
            console.log(error);
        });
    }

}

export interface checktoken {
    email_id: string;
}

export interface resetvalues {
    status: any[];
}
