import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpRequest, HttpEventType, HttpResponse} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {environment} from '../../environments/environment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import $ from 'jquery';
import {ImageCroppedEvent} from '../image-cropper/interfaces';
import {ImageCropperComponent} from '../image-cropper/component/image-cropper.component';
import {SharedService} from '../service/shared.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-profile-detail',
    templateUrl: './profile-detail.component.html',
    styleUrls: ['./profile-detail.component.css', './../people/people.component.css']
})
export class ProfileDetailComponent implements OnInit {

    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    token: string;
    slug: any;
    single_user_data: any[];
    image: any;
    fname: any;
    mobile: any;
    linkedin: any;
    ou: any;
    email: any;
    lname: any;
    edit = false;
    service_id: string;
    people_profile: any[] = [];
    linkdin: any;
    qans: any[] = [];
    all_all_sqqt: any[] = []
    company_logo: any = "";
    showEdit: boolean = false;
    user_slug: any;
    learinggoal: any[];
    lg_allqanda: any[] = [];
    openedit: boolean = true;
    service_name: string;

    //croping
    imageChangedEvent1: any = '';
    imageChangedEvent2: any = '';
    croppedImage1: any = '';
    croppedImage2: any = '';
    showCropper1 = false;
    showCropper2 = false;
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
    percentDoneOne: number = 0;
    percentDoneTwo: number = 0;
    image2: any;
    imageoneuploaded: boolean = false;
    imagetwouploaded: boolean = false;
    filename1: any = "Choose file";
    filename2: any = "Choose file";
    image1uploaded: any;
    user_role: string;
    profile_datavalue: any;
    user_actual_image: string;
    u_role: any;
    href_linkedin: string;
    main_people: any;
    check_comapny_question: any;
    fk_brand_id: string;
    introduction: any;

    //end croping

    constructor(private authservice: AuthService, private ar: ActivatedRoute, private router: Router, private httpclient: HttpClient, private shared: SharedService, private sanitizer: DomSanitizer) {
        this.service_name = localStorage.getItem('service_name')
        if (localStorage.getItem('library')) {
            this.service_id = localStorage.getItem('library')

        }
        if (localStorage.getItem('auth-token')) {
            this.token = localStorage.getItem('auth-token')
        }

        if (localStorage.getItem('user_role')) {
            this.user_role = localStorage.getItem('user_role')
        }
    }

    ngOnInit() {
        this.fk_brand_id = localStorage.getItem("fk_brand_id");

        this.authservice.hideshowheader(false);
        this.token = localStorage.getItem('auth-token');
        this.ar.params.subscribe((params) => {
            this.slug = params['slug'];
            this.profiledata(this.token, this.slug);
            this.getAllDetails();
            this.getLearningGoal();
        });


    }

    profiledata(token, slug) {
        let libraryvalue = localStorage.getItem('library');
        this.httpclient.get(this.api + "auth/profilepage?token=" + token + "&slug=" + slug + "&service_slug=" + libraryvalue).subscribe((data: singleuser) => {
            console.log(data);
            this.single_user_data = data.profiledata;
            console.log('user', this.single_user_data);

            this.email = this.single_user_data['email'];
            this.image = this.single_user_data['actual_image'];     //actual image
            console.log(this.image)
            this.image2 = this.single_user_data['overview_image'];
            console.log('image2', this.image2)
            //  localStorage.setItem('overview_image',this.image2)
            //overview_image
            console.log('overview_image');
            this.u_role = this.single_user_data['role'];
            if (this.image != '') {
                this.imageoneuploaded = true;
            }
            if (this.image2 != '') {
                this.imagetwouploaded = true;
            }
            this.fname = this.single_user_data['fname'];
            this.lname = this.single_user_data['lname'];
            this.mobile = this.single_user_data['mobile'];
            this.linkedin = this.single_user_data['linkdin'];
            if (this.single_user_data['linkdin']) {
                let url: string = '';
                if (!/^http[s]?:\/\//.test(this.single_user_data['linkdin'])) {
                    url += 'https://';
                }
                this.href_linkedin = url + this.single_user_data['linkdin'];
            }
            this.ou = this.single_user_data['own_user'];
            // localStorage.setItem('overview_image',this.image2);
        }, (error) => {
            console.log(error);
        })
    }

    getAllDetails() {
        this.httpclient.get(this.api + 'auth/profileinfo_for_people?token=' + this.token + '&user_slug=' + this.slug + "&service_slug=" + this.service_id).subscribe((data: people) => {
            this.people_profile = data.profile
            console.log('new', this.people_profile);
            this.mobile = this.people_profile['0'].mobile;
            this.linkdin = this.people_profile['0'].linkdin;
            if (this.people_profile['0'].linkdin) {
                let url: string = '';
                if (!/^http[s]?:\/\//.test(this.people_profile['0'].linkdin)) {
                    url += 'https://';
                }
                this.href_linkedin = url + this.people_profile['0'].linkdin;
            }
            this.qans = this.people_profile['0'].allqanda
            this.check_comapny_question = data.check_comapny_question
            this.user_slug = this.people_profile['0'].slug
            if (this.user_slug == localStorage.getItem('user_slug')) {
                this.showEdit = true;
                // if(this.openedit)
                // {

                //   this.edit=true;
                // }
            }

            for (let i = 0; i < this.qans.length; i++) {
                this.all_all_sqqt.push(this.qans[i].all_sqqt)
            }

            if (this.all_all_sqqt.length != 0) {
                if (this.all_all_sqqt['0'].length) {
                    this.company_logo = this.imgurl + "storage/company_img/resize_" + this.all_all_sqqt['0']['0'].comapny_logo
                }
            }

        })
    }


    editdiv() {
        if (this.edit == true) {
            window.location.reload();
        } else {
            this.edit = !this.edit;
        }
    }

    submitDetails() {
        const fd = new FormData()
        fd.append('mobile', this.mobile)
        fd.append('linkdin', this.linkdin)
        fd.append('user_slug', this.slug)
        fd.append('check_comapny_question', this.check_comapny_question)
        if (this.qans.length != 0) {
            fd.append('fk_service_id', this.qans[0].fk_service_id)
        }

        let check = ['Professional title', 'Current job', 'Work experience', 'Education', 'Personality', 'Expectations', 'single_line', 'multiple_line', 'Company'] //,'relevancy','slider'
        for (let i = 0; i < this.qans.length; i++) {
            console.log(this.qans[i]);
            for (let j = 0; j < check.length; j++) {
                if (check[j] == this.qans[i].sqa_questionheader) {
                    console.log($('#pe' + i).val())
                    fd.append('fk_qanda_id_' + i, this.qans[i].fk_qanda_id)
                    fd.append('questiontext_' + i, $('#pe' + i).val())
                }
                if (check[j] == this.qans[i].sqa_typeofquestion) {
                    console.log($('#pe' + i).val())
                    fd.append('fk_qanda_id_' + i, this.qans[i].fk_qanda_id)
                    fd.append('questiontext_' + i, $('#pe' + i).val())
                }
            }
            if (this.qans[i].sqa_typeofquestion == 'relevancy') {
                console.log($('input[name=' + 'pe' + i + ']' + ':checked').val());
                fd.append('fk_qanda_id_' + i, this.qans[i].fk_qanda_id)
                fd.append('questiontext_' + i, $('input[name=' + 'pe' + i + ']' + ':checked').val())
            }
            if (this.qans[i].sqa_typeofquestion == 'slider') {
                console.log($('input[name=' + 'pe' + i + ']' + ':checked').val());
                fd.append('fk_qanda_id_' + i, this.qans[i].fk_qanda_id)
                fd.append('questiontext_' + i, $('input[name=' + 'pe' + i + ']' + ':checked').val())
            }
        }

        console.log(this.qans);
        // this.qans.splice(0, 1);
        // console.log(this.qans);
        let count = String(this.qans.length)
        fd.append('total_count', count)

        this.httpclient.post(this.api + "auth/insert_service_qanda_question_text?token=" + this.token, fd).subscribe((data) => {
            this.all_all_sqqt = []
            this.openedit = false
            this.editdiv()
            this.getAllDetails()
            this.profiledata(this.token, this.slug)
        }, (error) => {
            console.log(error)
        })
    }

    getLearningGoal() {
        this.httpclient.get(this.api + 'auth/profileinfo_learninggoals?token=' + this.token + '&service_slug=' + this.service_id + "&user_slug=" + this.slug).subscribe((data: learninggoal) => {
            // console.log(data)
            // console.log(data.introduction)
            this.introduction = data.introduction;
            console.log(this.introduction)

            this.learinggoal = data.learinggoal;

            console.log(this.learinggoal);
            for (let i = 0; i < this.learinggoal.length; i++) {
                this.lg_allqanda.push(this.learinggoal[i].allqanda)
            }
            console.log(this.lg_allqanda)
        })
    }

    SaveLearningGoal(i, pid, slid) {
        console.log($('#letext' + i).val())
        console.log(i, pid, slid)
        console.log($('input[name=' + 'le' + i + ']' + ':checked').val());
        let textbox_text = $('#letext' + i).val()
        let slider = $('input[name=' + 'le' + i + ']' + ':checked').val()

        const fd = new FormData()
        fd.append('fk_service_id', this.learinggoal[0].sl_serviceid)
        fd.append('fk_profile_id', pid)
        fd.append('fk_learning_goal_id', slid)
        fd.append('learning_goal_text', textbox_text)
        fd.append('answer_slider', slider)
        this.httpclient.post(this.api + 'auth/insert_service_learning_answer?token=' + this.token, fd).subscribe((data) => {
            console.log(data)

        })
    }


    ///croping

    fileChangeEventOne(event: any) {
        let data = event;
        console.log(data.target.files['0'])
        if (data.target.files['0'].size > 5242880) {
            alert('Max. Image size: 5MB For Action shot');

            return false;
        }
        this.imageChangedEvent1 = event;
        this.filename1 = event.target.files['0'].name;
        console.log(this.imageChangedEvent1, '1')
    }

    fileChangeEventTwo(event: any) {
        let data = event;
        console.log(data.target.files['0'])
        if (data.target.files['0'].size > 5242880) {
            alert('Max. Image size: 5MB For Profile picture');

            return false;
        }
        this.imageChangedEvent2 = event;
        this.filename2 = event.target.files['0'].name;
        console.log(this.imageChangedEvent2, '2')
    }

    imageCroppedOne(event: ImageCroppedEvent) {
        this.croppedImage1 = event.base64;
        console.log(event, '1');

    }

    imageCroppedTwo(event: ImageCroppedEvent) {
        this.croppedImage2 = event.base64;
        console.log(event, '2');

    }

    imageLoadedOne() {
        this.showCropper1 = true;
        console.log('Image loaded 1')
    }

    imageLoadedTwo() {
        this.showCropper2 = true;
        console.log('Image loaded 2')
    }

    cropperReadyOne() {
        console.log('Cropper ready 1')
    }

    cropperReadyTwo() {
        console.log('Cropper ready 2')
    }

    loadImageFailedOne() {
        console.log('Load failed 1');
    }

    loadImageFailedTwo() {
        console.log('Load failed 2');
    }

    rotateLeft() {
        this.imageCropper.rotateLeft();
    }

    rotateRight() {
        this.imageCropper.rotateRight();
    }

    flipHorizontal() {
        this.imageCropper.flipHorizontal();
    }

    flipVertical() {
        this.imageCropper.flipVertical();
    }


    uploadImage(v) {
        if (v == 1) {

            if (this.imageChangedEvent1) {
                let file = [];
                let crop_image = this.croppedImage1;
                file.push({param: "actual_image", file: this.shared.dataURItoBlob(crop_image)})
                const fdone = new FormData()
                fdone.append('img_type', 'polaroid');
                fdone.append(file[0].param, file[0].file, file[0].file.name);
                const req = new HttpRequest('POST', this.api + "auth/update_profile_img?token=" + this.token, fdone, {
                    reportProgress: true,
                });
                this.httpclient.request(req).subscribe(event => {
                    // Via this API, you get access to the raw event stream.
                    // Look for upload progress events.
                    if (event instanceof HttpResponse) {
                        if (event.ok) {

                        }
                    }
                    if (event.type === HttpEventType.UploadProgress) {
                        // This is an upload progress event. Compute and show the % done:
                        this.percentDoneOne = Math.round(100 * event.loaded / event.total);
                        console.log(this.percentDoneOne)
                        if (this.percentDoneOne == 100) {
                            this.percentDoneOne = 0;
                            this.filename1 = "Choose file";
                            this.image = "";
                            this.getprofiledetails(this.token);
                            setTimeout(() => {
                                this.profiledata(this.token, this.slug);
                                this.imageoneuploaded = true
                            }, 1000)


                        }
                    } else if (event instanceof HttpResponse) {

                    }
                });

            } else {
                alert('Select action shot')
            }
        }
        if (v == 2) {
            if (this.imageChangedEvent2) {
                let file = [];
                let crop_image = this.croppedImage2;
                file.push({param: "overview_image", file: this.shared.dataURItoBlob(crop_image)})

                const fdone = new FormData()
                fdone.append('img_type', 'overview');
                fdone.append(file[0].param, file[0].file, file[0].file.name);
                const req = new HttpRequest('POST', this.api + "auth/update_profile_img?token=" + this.token, fdone, {
                    reportProgress: true,
                });
                this.httpclient.request(req).subscribe(event => {
                    // Via this API, you get access to the raw event stream.
                    // Look for upload progress events.
                    if (event instanceof HttpResponse) {
                        if (event.ok) {

                        }
                    }
                    if (event.type === HttpEventType.UploadProgress) {
                        // This is an upload progress event. Compute and show the % done:
                        this.percentDoneTwo = Math.round(100 * event.loaded / event.total);
                        if (this.percentDoneTwo == 100) {
                            this.percentDoneTwo = 0;
                            this.filename2 = "Choose file";
                            this.image2 = "";
                            this.getprofiledetails(this.token);
                            setTimeout(() => {
                                this.profiledata(this.token, this.slug);
                                this.imagetwouploaded = true
                            }, 1000)
                        }

                    } else if (event instanceof HttpResponse) {

                    }
                });

            } else {
                alert('Select profile picture')
            }
        }
    }

    tomainpage() {
        location.reload();
        // window.location.href = 'profile-detail/2c3ec96e4f';
        // this.router.navigate(["/'profile-detail/2c3ec96e4f"]);
    }

    getprofiledetails(tokenvalue) {
        let overview_image = ''
        let actual_image = ''
        this.httpclient.get(this.api + "auth/profiledata?token=" + tokenvalue).subscribe((data: profile) => {
            this.profile_datavalue = data.profile;


            overview_image = this.profile_datavalue['overview_imgae'];
            actual_image = this.profile_datavalue['actual_image'];
            if (overview_image == null) {
                this.user_actual_image = './assets/img/profile_blank.png';
            } else {
                this.user_actual_image = this.imgurl + 'storage/profile_img/resize_' + actual_image;

            }
            localStorage.setItem('user_actual_image', this.user_actual_image)
            localStorage.setItem('overview_image', overview_image)
        }, (error) => {
            console.log(error);
        })
    }

    topMenu(v) {
        this.main_people = v
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}

export interface singleuser {
    profiledata: any[];
}

export interface people {
    profile?: any;
    check_comapny_question: any
}

export interface learninggoal {
    introduction: any;
    learinggoal: any[],
    status?: any
}


export interface profile {
    profile: any[];
}

