import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth.service';
import {HttpClient} from '@angular/common/http';
import {SharedService, user_accessInterface} from '../service/shared.service';
import $ from 'jquery';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from 'src/environments/environment';


@Component({
    selector: 'app-reflections',
    templateUrl: './reflections.component.html',
    styleUrls: ['./reflections.component.css']
})
export class ReflectionsComponent implements OnInit {
    reflections: any[] = [];
    token: string;
    serviceslug: string;
    index: any;
    pageid: any;
    value: any;
    sortedReflection: any[] = [];
    imgurl = environment.imageurl;

    //user access
    useraccess: user_accessInterface = {
        isaddnote: "",
        isaddreflection: "",
        islivebookserviceaccess: "",
        isprofnotesshowlivebook: "",
        isprogram: "",
        isroleid: "",
        isuploadassignments: "",
        isworkshop: "",
        view_notes: ""
    }
    isAllowed: any;
    introduction: any;
    overview_image: any;
    actual_image: any;
    user_actual_image: string;

    constructor(public authservice: AuthService, private httpclient: HttpClient, private shared: SharedService, private domSanitizer: DomSanitizer) {
        this.overview_image = localStorage.getItem('overview_image');
        this.actual_image = localStorage.getItem('user_actual_image');
        if (this.actual_image == null) {
            this.user_actual_image = './assets/img/profile_blank.png';
        } else {
            this.user_actual_image = this.actual_image;
        }
    }

    ngOnInit() {
        this.authservice.hideshowheader(false);
        this.token = localStorage.getItem('auth-token')
        this.serviceslug = localStorage.getItem('library')
        //Reflections

        this.getReflection()
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    //reflection
    getReflection() {
        this.httpclient.get(this.shared.api + "auth/all_service_reflections?token=" + this.token + "&service_slug=" + this.serviceslug).subscribe((data: reflection_interface) => {
            this.reflections = data.data;
            this.sortedReflection = [];
            let unique_name = [];
            let m_name = "";
            for (let i = 0; i < this.reflections.length; i++) {
                m_name = this.reflections[i].module_name;

                if (unique_name.includes(m_name)) {
                    this.reflections[i].module_name = '';
                } else {
                    unique_name.push(m_name);
                }
                this.sortedReflection.push(this.reflections[i]);
            }
            console.log(this.sortedReflection);


            this.httpclient.get(this.shared.api + 'auth/access_rights?token=' + this.token + "&slug=" + this.serviceslug).subscribe((data: userroalaccess) => {
                let user_access = data.role;
                this.useraccess.isaddnote = user_access['0'].add_notes
                this.useraccess.isaddreflection = user_access['0'].add_reflections
                this.useraccess.islivebookserviceaccess = user_access['0'].livebook_service_access
                this.useraccess.isprofnotesshowlivebook = user_access['0'].prof_notes_show_livebook
                this.useraccess.isprogram = user_access['0'].program         //program like tutor student and all
                this.useraccess.isroleid = user_access['0'].rl_id            // user roals id 1 2 3 ...
                this.useraccess.isuploadassignments = user_access['0'].upload_assignments
                this.useraccess.isworkshop = user_access['0'].workshop        // workshop name
                this.useraccess.view_notes = user_access['0'].view_notes        // to view note or not


                this.isAllowed = this.useraccess.isaddreflection;

                this.introduction = this.domSanitizer.bypassSecurityTrustHtml('<style>#maincontent  ul.key-questions {padding:0px} ul.key-questions li span.number {color:black !important; padding-right: 8px;} ul.key-questions li {padding-bottom: 0px !important;} ul{margin-bottom:0px !important}</style>');
            })
        })
    }

    sendReflectionData(i, pid, cid, sid, mid, clusid, allowed) {
        this.index = i
        this.pageid = pid
        console.log($('#reftext' + i).val())

        this.value = $('#reftext' + i).val()
        this.httpclient.post(this.shared.api + "auth/insert_reflection?token=" + this.token + "&page_id=" + this.pageid + "&reflection_text=" + this.value + '&chapter_id=' + cid + "&service_id=" + sid + "&module_id=" + mid + "&cluster_id=" + clusid + '&allow_student=' + allowed, "").subscribe((data: reflectiontext) => {
            console.log(data);

        })
    }

    ngOnDestroy() {
        //   if(this.value !=undefined)
        //   {
        // this.sendReflectionData(this.index,this.pageid)
        //   }
    }
}

export interface reflection_interface {
    data: any;
}

export interface reflectiontext {
    status: any;
    message: any;
}

export interface userroalaccess {
    role: any;
}