import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http'
import {AuthService} from '../service/auth.service';

@Component({
    selector: 'app-autologin',
    templateUrl: './autologin.component.html',
    styleUrls: ['./autologin.component.css']
})
export class AutologinComponent implements OnInit {
    email: any;
    encrypted: any;
    message: any;


    constructor(private activeroute: ActivatedRoute, private router: Router, private httpclient: HttpClient, private authservice: AuthService) {
    }

    ngOnInit() {

        this.activeroute.queryParams.subscribe((params: Params) => {
            this.email = params['id'];

            if (this.authservice.isLoggedIn()) {
                this.router.navigate(['/news']);

            } else {
                this.authservice.autoLogin(this.email).subscribe((data: singininterface) => {

                    if (data.status) {
                        localStorage.setItem('auth-token', data.token)
                        localStorage.setItem('user_slug', data.user_slug)
                        localStorage.setItem('tutor_ornot', data.tutor_ornot)
                        localStorage.setItem('auto-login', 'true');
                        this.router.navigate(['/news'])
                    }
                    if (!data.status) {
                        this.message = data.message
                    }
                })
            }
        })
    }


}


export interface singininterface {
    status: boolean,
    message: string,
    token?: any,
    user_slug: any;
    tutor_ornot: any,

}