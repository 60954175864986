import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

    constructor(public authservice: AuthService) {
    }

    ngOnInit() {
        this.authservice.hideshowheader(false);
    }

}
