import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../service/auth.service';
import {Router, ActivatedRoute} from '@angular/router';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    signin: FormGroup;
    singinformtouched: boolean = false;
    message: string;
    redirectURL: any;
    encrypted: any;
    tokenFromUI: any = "gtGl/QPpkF1MxlURGLgYmWlVbyztmgGLgszm1V+cdGk=";


    constructor(private singinfb: FormBuilder, private authservice: AuthService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.tokenFromUI = "gtGl/QPpkF1MxlURGLgYmWlVbyztmgGLgszm1V+cdGk=";
        let params = this.route.snapshot.queryParams;
        if (params['redirectURL']) {
            this.redirectURL = params['redirectURL'];
            console.log(this.redirectURL)
        }
        if (localStorage.getItem('auth-token')) {
            this.router.navigate(['/news'])
        }
        this.signin = this.singinfb.group({
            'email': ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            'password': ['', [Validators.required]]
        })
        this.authservice.hideshowheader(false);


    }

    userSignin(data) {

        this.singinformtouched = true;
        if (this.signin.valid) {
            this.authservice.signin(data).subscribe((data: singininterface) => {

                if (data.status) {
                    localStorage.setItem('auth-token', data.token)
                    localStorage.setItem('user_slug', data.user_slug)
                    localStorage.setItem('tutor_ornot', data.tutor_ornot)

                    // let r1 = this.randomOne(5);
                    // let r2 = this.randomTwo(5);
                    // let key = window.btoa(this.signin.value.password)
                    // this.encrypted = r1 + key + r2;
                    // localStorage.setItem('email', this.signin.value.email)
                    // localStorage.setItem('tutor', this.encrypted)

                    if (this.redirectURL) {
                        this.router.navigateByUrl('/refresh', {skipLocationChange: false}).then(() =>
                            this.router.navigateByUrl(this.redirectURL,))
                        this.router.navigateByUrl(this.redirectURL,)
                            .catch(() => this.router.navigate(['/news']))
                    } else {

                        this.router.navigate(['/news'])

                    }


                }
                if (!data.status) {
                    this.message = data.message
                }
            })
        }
    }


    randomOne(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    randomTwo(length) {
        var result = '';
        var characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


}


export interface singininterface {
    status: boolean,
    message: string,
    token?: any,
    user_slug: any;
    tutor_ornot: any,

}



