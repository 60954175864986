import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {environment} from '../../environments/environment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {$} from 'jquery';
import {SharedService} from '../service/shared.service';

@Component({
    selector: 'app-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.css']
})
export class LibraryComponent implements OnInit {
    panelOpenState = false;
    tokenvalue: string;
    end_access_info: SafeHtml | null;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    librarydata: any[];
    aid: any;
    profile_datavalue: any;
    servicename: string;
    img: any;
    brand_name: any;
    main_font: string;
    second_font: string;
    service_enforce: any = 0;
    first_module_id: string;
    fk_brand_id: string;

    constructor(private authservice: AuthService, private ar: ActivatedRoute, private router: Router, private httpclient: HttpClient, private shareservice: SharedService, private sanitizer: DomSanitizer) {
        if (localStorage.getItem('service_enforce') == '1') {

            this.service_enforce = localStorage.getItem('service_enforce')
        }
    }

    ngOnInit() {
        this.fk_brand_id = localStorage.getItem("fk_brand_id");

        this.authservice.hideshowheader(false);
        this.tokenvalue = localStorage.getItem('auth-token');
        this.shareservice.servicename.subscribe(v => {
            this.servicename = v
        })
        this.getprofiledetails(this.tokenvalue);

        if (localStorage.getItem('auth-token') == null) {
            this.router.navigate(['/login'])
        } else {
            this.tokenvalue = localStorage.getItem('auth-token');

            this.ar.params.subscribe((params) => {
                this.aid = params['m_id'];
                console.log(this.aid)
                localStorage.setItem('library', this.aid);
                this.library_value(this.tokenvalue, this.aid);
            });
        }

    }

    library_value(token, aid) {
        this.httpclient.get(this.api + "auth/modulelisting?token=" + token + "&service_slug=" + aid).subscribe((data: modulesdata) => {
            this.librarydata = data.modules;
            this.end_access_info = this.sanitizer.bypassSecurityTrustHtml(data.end_access_info);
            this.img = data.image;
            console.log(this.librarydata)
            this.brand_name = data.brand_name;
            if (this.brand_name == 'park') {
                this.main_font = "SilkaMono";
                this.second_font = "'Roboto', sans-serif";
            }
            if (this.brand_name == 'grow') {
                this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brand_name == 'coty') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";

            }
            if (this.brand_name == 'lego') {
                this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
                this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brand_name == "raymond") {
                this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brand_name == "gsk") {
                this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
                this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brand_name == "electrolux") {
                this.main_font = "'electrolux-sans-semibold'"
                this.second_font = "'electrolux-regular'";
            }


            if (localStorage.getItem('service_enforce') == '1') {

                // for(let ld of this.librarydata)
                // {
                //   if((this.service_enforce==1 ) && (ld.module_blur==1 && ld.module_status==1 ))   // this.service_enforce==1 && ld.module_status==1
                //   {
                //     ld['enforce']=true;
                //     if(ld.module_status=='1')
                //     {
                //       this.first_module_id=ld.id;
                //       // this.forEnforce();
                //       return true;
                //     }
                //     return true;

                //   }
                //   else
                //   {
                //     ld['enforce']=false;
                //     if(ld.module_status=='1')
                //     {
                //       this.first_module_id=ld.id;
                //       // this.forEnforce();

                //     }
                //   }

                // }
            }

            // console.log(this.service_enforce)
            // if(this.service_enforce=='1')
            // {
            //   alert('in')
            //   for(let ld of this.librarydata)
            //   {

            //   }
            // }

            console.log(this.librarydata)

        }, (error) => {
            console.log(error);
        });
    }

    getprofiledetails(tokenvalue) {
        this.httpclient.get(this.api + "auth/profiledata?token=" + tokenvalue).subscribe((data: profile) => {
            this.profile_datavalue = data.profile;
        }, (error) => {
            console.log(error);
        })
    }


    setModuleStatus(v) {

        localStorage.setItem('modulestatus', v)

    }


    forEnforce() {
        let params = new HttpParams().set('type', 'module');
        params = params.set('service_slug', this.aid);
        params = params.set('mod_or_chapter_id', this.first_module_id);
        this.httpclient.get(this.api + "auth/insert_module_chapter_blur?token=" + this.tokenvalue, {params: params}).subscribe((data) => {
            console.log(data)
        }), (error) => {
            console.log(error)
        }
    }

}

export interface modulesdata {
    modules: any[];
    image: any;
    brand_name: any;
    end_access_info: string | null;
}

export interface profile {
    profile: any[];
}