import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth.service';
import {HttpClient} from '@angular/common/http'
import {SharedService} from '../service/shared.service';
import {environment} from 'src/environments/environment';
import $ from 'jquery';

@Component({
    selector: 'app-note-summary',
    templateUrl: './note-summary.component.html',
    styleUrls: ['./note-summary.component.css']
})
export class NoteSummaryComponent implements OnInit {
    token: string;
    serviceslug: string;
    assignments: any[] = [];
    imgurl = environment.imageurl;
    reflections: any;
    edit_note = false;
    allnotes: any[] = [];
    mynotes: any[] = [];
    edit_id_value: any;
    edit_text_value: any;
    edit_file_value: any;
    edit_file: File;
    edit_file_name: string;
    edit_allow_upload: boolean = false;
    page_id: any;
    edit_notetype_value: any;
    nt_privacy_note: string;
    isprogram: any;
    loading = false;
    service_name: string;
    image: any;
    single_user_data: any;
    user_slug: string;
    fk_service_id: string;
    fk_brand_id: string;

    constructor(public authservice: AuthService, private http: HttpClient, private shared: SharedService) {
        this.service_name = localStorage.getItem('service_name')
        this.token = localStorage.getItem('auth-token')
        this.user_slug = localStorage.getItem('user_slug')
        this.serviceslug = localStorage.getItem('library')
        this.http.get(this.shared.api + 'auth/access_rights?token=' + this.token + "&slug=" + this.serviceslug).subscribe((data: userroalaccess) => {
            let user_access = data.role;

            this.isprogram = user_access['0'].program         //program like tutor student and all
            if (this.isprogram == 'Hidden') {
                this.nt_privacy_note = "2"
            }
        })
    }

    ngOnInit() {

        this.fk_brand_id = localStorage.getItem("fk_brand_id");


        this.authservice.hideshowheader(false);
        this.profiledata(this.token, this.user_slug)

//Reflections has own component

//assignments overview
        this.getAasignment()


//get notes
        this.getNotes()
    }


    //assignments overview
    getAasignment() {
        this.http.get(this.shared.api + "auth/assignment_pages?token=" + this.token + "&service_slug=" + this.serviceslug).subscribe((data: assignment_interface) => {

            this.assignments = data.assignments
            console.log('assign', this.assignments);
        })
    }


    //get notes
    getNotes() {
        this.loading = true
        //all notes
        this.http.get(this.shared.api + 'auth/summury_notes?token=' + this.token + "&service_slug=" + this.serviceslug + "&privacy_note=1").subscribe((data: notes_interface) => {
            this.allnotes = data.data
            this.loading = false;
        }, (error) => {
            this.loading = false;
        })

        //my  notes
        this.http.get(this.shared.api + 'auth/summury_notes?token=' + this.token + "&service_slug=" + this.serviceslug + "&privacy_note=0").subscribe((data: notes_interface) => {
            this.mynotes = data.data
            this.loading = false;
        }), (error) => {
            this.loading = false;
        }
    }

    showHideEditNotes(id, text, file, pageid, notetype) {    //check,id,text,file,notetype
        // this.note_or_reaction=check
        // check 1 == note and 2 ==reaction
        console.log(id, text, file, pageid, notetype)
        this.edit_note = !this.edit_note
        this.edit_id_value = id;
        this.edit_text_value = text;
        this.edit_file_value = file;
        this.page_id = pageid
        this.edit_notetype_value = notetype;
    }

    editNoteFile($event) {
        var file: File = $event.target.files[0];
        if (file) {
            // if (!this.validateFile(file)) {
            //   alert("Unsupported File format");
            //   return false;
            // }
            // if (file.size > 5242880) {
            //   alert("Max. File size: 5MB");
            //   return false;
            // }
            this.edit_file = file
            this.edit_file_name = file.name
            this.edit_allow_upload = true
        }

    }

    editNotes() {
        const fd = new FormData()
        fd.append('nt_privacy_note', this.edit_notetype_value)
        fd.append('nt_description', this.edit_text_value)
        fd.append('nt_id', this.edit_id_value)
        if (this.edit_allow_upload) {
            fd.append('nt_attachment', this.edit_file, this.edit_file_name)
        } else {
            fd.append('old_nt_attachment', this.edit_file_value)
        }
        this.http.post(this.shared.api + 'auth/update_note?token=' + this.token + "&page_id=" + this.page_id, fd).subscribe((data) => {
            console.log(data)
            this.edit_note = false;
            this.allnotes = [];
            this.mynotes = [];
            this.getNotes()
            $('.modal-backdrop').removeClass('fade')
            $('.modal-backdrop').removeClass('fade')
            $('.modal-backdrop').removeClass('modal-backdrop')

        })
    }

    profiledata(token, slug) {
        let libraryvalue = localStorage.getItem('library');
        this.http.get(this.shared.api + "auth/profilepage?token=" + token + "&slug=" + slug + "&service_slug=" + libraryvalue).subscribe((data: singleuser) => {
            this.single_user_data = data.profiledata;


            this.image = this.single_user_data['actual_image'];      //overview_image


        }, (error) => {
            console.log(error);
        })
    }

    modalClose() {
        this.edit_note = false;

    }

    ngOnDestroy() {
        $('.modal-backdrop').removeClass('fade')
        $('.modal-backdrop').removeClass('fade')
        $('.modal-backdrop').removeClass('modal-backdrop')

    }

    splitImageName(path) {
        var parts = path.split("/");
        return parts[parts.length - 1];
    }

}

export interface assignment_interface {
    assignments: any;
}

export interface notes_interface {
    data: any
}

export interface userroalaccess {
    role: any;
}


export interface singleuser {
    profiledata: any[];
}