import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router"
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import $ from 'jquery';
import {SharedService} from '../service/shared.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    tokenvalue: string;
    profile_datavalue: any[];
    impurl = environment.imageurl;

    email: string;
    password: any;
    encrypted: string;
    is_tutor: string;
    auto_login: any = 'false'
    user_actual_image: any;
    tutor_portal: any = environment.imageurl;

    companyLogo: string;
    token: any;
    servicedata: any[];
    content: boolean;


    constructor(private router: Router, private httpclient: HttpClient, private shared: SharedService) {

    }

    ngOnInit() {
        this.tokenvalue = localStorage.getItem('auth-token');

        if (localStorage.getItem('tutor_ornot') == '1') {

            this.is_tutor = localStorage.getItem('tutor_ornot');
            this.email = localStorage.getItem('email');
            this.encrypted = localStorage.getItem('tutor');
            $('#email').val(this.email);
            $('#password').val(this.encrypted);
        }

        if (localStorage.getItem('auto-login')) {
            this.auto_login = localStorage.getItem('auto-login')
        }
        if (localStorage.getItem('tutor_ornot') == '1' && this.auto_login == 'false') {
            $('#tutorlogin').css('display', 'none')
        }

        this.getprofiledetails(this.tokenvalue);
        // this.getdrager(this.token);

    }

    signOut() {
        if (localStorage.getItem('iframe') == "1") {
            localStorage.clear();
            this.router.navigate(["/login-redirect"]);
        } else {
            localStorage.clear();
            this.router.navigate(["/login"]);
        }
    }

    getprofiledetails(tokenvalue) {
        let overview_image = ''
        let actual_image = ''
        this.httpclient.get(this.api + "auth/profiledata?token=" + tokenvalue).subscribe((data: profile) => {
            this.profile_datavalue = data.profile;
            localStorage.setItem('fname', this.profile_datavalue['fname'])

            overview_image = this.profile_datavalue['overview_imgae'];
            actual_image = this.profile_datavalue['actual_image'];
            if (actual_image == '') {
                this.user_actual_image = './assets/img/profile_blank.png';
            } else {
                this.user_actual_image = this.imgurl + 'storage/profile_img/resize_' + actual_image;
            }
            localStorage.setItem('user_actual_image', this.user_actual_image)
            localStorage.setItem('overview_image', overview_image)

            this.companyLogo = this.profile_datavalue['company_logo'];

            console.log(this.profile_datavalue)
        }, (error) => {
            console.log(error);
        })

    }


}

export interface profile {
    profile: any[];
}

export interface servicedata {
    my_service: any[];
}
