import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {environment} from '../../environments/environment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {$} from 'jquery';
import {DomSanitizer} from '@angular/platform-browser';
import {SharedService, roleModuleChapterNameInterface} from '../service/shared.service';


@Component({
    selector: 'app-books',
    templateUrl: './books.component.html',
    styleUrls: ['./books.component.css']
})
export class BooksComponent implements OnInit {
    service_slug: string;
    aid: any;
    tokenvalue: string;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    singlemodule: any[];
    chapter_data: any[] = [];
    overview: any;
    preperation: any;
    preperation_status: any;
    overview_staus: any;
    module_name: any;
    serviceslug: string;
    brand_name: any;
    main_font: string;
    second_font: string;
    mtype: string;
    service_enforce: any = 0;
    module_status: any;
    bullet_back_src: string;
    bullet_position_top = 8;
    link_back_src: string;
    brand_color: any;
    first_chapter_id: string;
    fk_brand_id: any;


    constructor(private authservice: AuthService, private ar: ActivatedRoute, private router: Router, private httpclient: HttpClient, private domSanitizer: DomSanitizer, private shareservice: SharedService) {
        this.service_slug = localStorage.getItem('library');
    }

    ngOnInit() {
        this.fk_brand_id = localStorage.getItem("fk_brand_id")


        this.authservice.hideshowheader(true);

        if (localStorage.getItem('auth-token') == null) {
            this.router.navigate(['/login']);
        } else {
            this.ar.params.subscribe((params) => {

                this.tokenvalue = localStorage.getItem('auth-token');
                this.aid = params['c_id'];
                this.mtype = params['mtype']
                this.getRolesModuleChap();
                this.livebookdata(this.tokenvalue, this.aid);
                this.chapterdata(this.tokenvalue, this.aid);
                if (localStorage.getItem('modulestatus')) {
                    this.module_status = localStorage.getItem('modulestatus')
                }
                this.shareservice.module_status.subscribe(v => {
                    this.module_status = v
                })
            });
        }
    }

    livebookdata(token, id) {
        this.httpclient.get(this.api + "auth/singlemodule?token=" + token + "&slug=" + id+ "&service_slug=" + this.service_slug).subscribe((data: livebook_data) => {
            this.singlemodule = data.module;
            this.serviceslug = data.service_slug;
            this.brand_name = data.brand_name

            this.brand_color = data.color
            console.log(data)
            localStorage.setItem('library', this.serviceslug);
            if (localStorage.getItem('service_enforce') == '1') {
                this.service_enforce = localStorage.getItem('service_enforce')
            }

            if (this.brand_name == 'park') {
                this.main_font = "SilkaMono";
                this.second_font = "'Roboto', sans-serif";
                this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-blue.svg";
                this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-blue.svg" //give it to <a.external> tag
                this.bullet_position_top = 7;
            }
            if (this.brand_name == 'grow') {
                this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-blue.svg";
                this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-blue.svg" //give it to <a.external> tag
            }
            if (this.brand_name == 'coty') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-purple.svg";
                this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-purple.svg" //give it to <a.external> tag

            }
            if (this.brand_name == 'lego') {
                this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
                this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-lilac.svg";
                this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-lilac.svg";
                this.bullet_position_top = 3;
            }
            if (this.brand_name == "raymond") {
                this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.bullet_back_src = "./assets/img/snippet/BrandElements/Raymond/bullet-raymond-red.svg";
                this.link_back_src = "./assets/img/snippet/BrandElements/Raymond/external-raymond-red.svg" //give it to <a.external> tag
            }
            if (this.brand_name == "gsk") {
                this.brand_color = '#d14e00'
                this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
                this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-orange.svg";
                this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-orange.svg" //give it to <a.external> tag
            }
            if (this.brand_name == 'drager') {
                this.main_font = "draeger"
                this.second_font = "draeger2";
                this.brand_color = '#0f4da2'
                this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-brand-color.svg";
                this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-brand-color.svg" //give it to <a.external> tag
            }
            if (this.brand_name == 'electrolux') {
                this.main_font = "'electrolux-sans-semibold'"
                this.second_font = "'electrolux-regular'";
                this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-blue.svg";
                this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-blue.svg" //give it to <a.external> tag
            }

            // {padding: 10px 20px 14px 20px;
            this.overview_staus = this.singlemodule[0]['modoverview_enabled'];
            this.overview = this.domSanitizer.bypassSecurityTrustHtml(this.singlemodule[0]['module_overview'] + '<style>#modoverview h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + ';color:' + this.brand_color + '}  #modoverview ul,li,p,strong{font-family:' + this.second_font + ';margin-bottom:0px !important} #modoverview ul.list { width:auto; padding-left:0px; } #modoverview .simple-media-block {background:transparent !important} #modoverview ul.list li { padding:0 0 0 25px; background:url("' + this.bullet_back_src + '") 0 ' + this.bullet_position_top + 'px no-repeat; list-style-type: none; font-size:17px  }  a.external { display: block;color:' + this.brand_color + '!important; line-height: 25px;float: left;background: url("' + this.link_back_src + '") 0 7px no-repeat;padding: 0 0 7px 21px !important; } #modoverview img.visual {margin: 0 0 28px 20px;display: block;} #modoverview div.hr { height: 15px }  #modoverview .simple-media-block div {margin:0px !important;} #modoverview .simple-media-capture{padding:20px 20px; background: #EBE7E4;position:relative;top:-12px !important}  </style>');

            this.preperation_status = this.singlemodule[0]['prep_enabled'];
            this.preperation = this.domSanitizer.bypassSecurityTrustHtml(this.singlemodule[0]['preperation'] + '<style>#preperation h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + ';color:' + this.brand_color + '}  #preperation ul,li,p,strong{font-family:' + this.second_font + ';margin-bottom:0px !important} #preperation ul.list { width:auto; padding-left:0px; } #preperation .simple-media-block {background:transparent !important} #preperation ul.list li { padding:0 0 0 25px; background:url("' + this.bullet_back_src + '") 0 ' + this.bullet_position_top + 'px no-repeat; list-style-type: none; font-size:17px}  a.external { display: block;color:' + this.brand_color + '!important; line-height: 25px;float: left;background: url("' + this.link_back_src + '") 0 7px no-repeat;padding: 0 0 7px 21px !important; } #preperation img.visual {margin: 0 0 28px 20px;display: block;} #preperation div.hr { height: 15px }  #preperation .simple-media-block div {margin:0px !important;} #preperation .simple-media-capture {padding:20px 20px;background: #EBE7E4;position:relative;top:-12px !important} </style>');

        }, (error) => {
            console.log(error);
        })
    }

    chapterdata(token, id) {
        this.httpclient.get(this.api + "auth/chapterlisting?token=" + token + "&slug=" + id + "&mtype=" + this.mtype+ "&service_slug=" + this.service_slug).subscribe((data: cluster) => {
            this.chapter_data = data.cluster;
            console.log(this.chapter_data)

            localStorage.setItem('modulestatus', data.module_status);

            console.log();
            this.shareservice.moduleStatusValue(data.module_status)
            this.first_chapter_id = this.chapter_data['0']['chapter']['0']['id'];

            if (localStorage.getItem('service_enforce') == '1') {

                // for(let cd of this.chapter_data)
                // {
                //  for(let c of cd.chapter)
                //  {
                //   if(this.service_enforce==1  && c.chapter_blur==1)
                //  {
                //   c['enforce']=true;
                //   // this.forEnforce();
                //   // return true;
                //  }
                //  }
                // }


            }


        }, (error) => {
            console.log(error);
        })
    }

    redirectchapterpage(sid, mid, clusid, slug, cname) {
        localStorage.setItem('livebook', this.aid);
        this.tokenvalue = localStorage.getItem('auth-token');


        this.httpclient.get(this.api + "auth/user_chapter_tracking?token=" + this.tokenvalue + "&slug=" + slug).subscribe((data: chapter_tracking) => {
            // console.log(data)
            this.router.navigate(['/Module/' + sid + '/' + mid + '/' + clusid + '/' + slug + '/' + '0' + "/" + this.mtype]);
            console.log(data)

        }, (error) => {
            console.log(error);
        });

        localStorage.setItem('chapter_name', cname)
    }

    getRolesModuleChap() {
        this.shareservice.getRolesModuleChapterName(this.tokenvalue, this.aid, "0").subscribe((data: roleModuleChapterNameInterface) => {
            console.log(data)
            this.module_name = data.module_name;

        })
    }

    forEnforce() {

        let params = new HttpParams().set('type', 'chapter');
        params = params.set('service_slug', this.serviceslug);
        params = params.set('mod_or_chapter_id', this.first_chapter_id);
        this.httpclient.get(this.api + "auth/insert_module_chapter_blur?token=" + this.tokenvalue, {params: params}).subscribe((data) => {
            console.log(data)
        }), (error) => {
            console.log(error)
        }
    }
}

export interface livebook_data {
    module: any[];
    service_slug: string;
    brand_name: any;
    color: any;
}

export interface cluster {
    slug: string;
    cluster: any[];
    module_status: any;
}

export interface chapter_tracking {
    status: boolean;
}