import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {routes, routingComponents} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {MatMenuModule} from '@angular/material';
import {OrderModule} from 'ngx-order-pipe';

import {MatExpansionModule} from '@angular/material/expansion';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {from} from 'rxjs';
import {MatSliderModule} from '@angular/material/slider';
// import { FontAwesomeModule } from '@fortawesome/fontawesome-free';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';

//custom pipe
import {SanitizeHtmlPipe} from './sanitize-html.pipe';

// import { Ng5SliderModule } from 'ng5-slider';
// import { SwiperModule } from 'angular2-useful-swiper';

import {SwiperModule} from 'ngx-swiper-wrapper';
import {SWIPER_CONFIG} from 'ngx-swiper-wrapper';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

//angular material
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ResetpasswordComponent} from './resetpassword/resetpassword.component';
import {GrowOnethirdComponent} from './grow-onethird/grow-onethird.component';
import {GrowHalfhalfComponent} from './grow-halfhalf/grow-halfhalf.component';
import {GrowFullpageComponent} from './grow-fullpage/grow-fullpage.component';
import {ClosureComponent} from './closure/closure.component';
import {ProfileDetailComponent} from './profile-detail/profile-detail.component';
import {MessagesComponent} from './messages/messages.component';
import {GrowFullScreenComponent} from './grow-full-screen/grow-full-screen.component';
import {AssignmentOverviewComponent} from './assignment-overview/assignment-overview.component';
import {SetpasswordComponent} from './setpassword/setpassword.component';
import {ImageCropperModule} from './image-cropper/image-cropper.module';


import {RefreshComponent} from './refresh/refresh.component';
import {PageOverviewComponent} from './page-overview/page-overview.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';

import {AutologinComponent} from './autologin/autologin.component';

import {LayoutModule} from '@angular/cdk/layout';
import {LoginRedirectComponent} from './login-redirect/login-redirect.component';
import {ForgotPasswordRedirectComponent} from './forgot-password-redirect/forgot-password-redirect.component';
import {ResetPasswordRedirectComponent} from './reset-password-redirect/reset-password-redirect.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ForbiddenComponent} from "./forbidden/forbidden.component";
import { Router } from '@angular/router';
import {AuthInterceptor} from "./service/auth.interceptor";


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    autoHeight: true
};


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        FooterComponent,
        ForbiddenComponent,
        HeaderComponent,
        routingComponents,
        ResetpasswordComponent,
        GrowOnethirdComponent,
        GrowHalfhalfComponent,

        GrowFullpageComponent,
        ClosureComponent,
        ProfileDetailComponent,
        MessagesComponent,
        GrowFullScreenComponent,
        SanitizeHtmlPipe,
        AssignmentOverviewComponent,
        SetpasswordComponent,
        RefreshComponent,
        PageOverviewComponent,

        AutologinComponent,

        LoginRedirectComponent,

        ForgotPasswordRedirectComponent,

        ResetPasswordRedirectComponent


        // Ng5SliderModule,


    ],
    imports: [
        routes,
        BrowserModule,
        MatMenuModule,
        MatExpansionModule,
        BrowserAnimationsModule,
        MatSliderModule,

        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SwiperModule,
        MatButtonModule,
        MatIconModule,
        OrderModule,
        ImageCropperModule,
        SlickCarouselModule,
        LayoutModule
    ],
    providers: [
        DeviceDetectorService,
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: function(router: Router) {
                return new AuthInterceptor(router);
            },
            multi: true,
            deps: [Router]
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
