import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../service/auth.service';

@Component({
    selector: 'app-refresh',
    templateUrl: './refresh.component.html',
    styleUrls: ['./refresh.component.css']
})
export class RefreshComponent implements OnInit {

    constructor(private router: Router, private authservice: AuthService) {
    }

    ngOnInit() {
        if (this.authservice.isLoggedIn()) {
            this.router.navigate[('/news')]
        } else {
            this.router.navigate[('/login')]
        }
    }

}
