import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {$} from 'jquery';
import {SharedService} from '../service/shared.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
    tokenvalue: string;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    newsdata: any[];
    allfoutdata: any[];
    servicedata: any[];
    new1: any[];
    name: any;
    My: number;
    brandname: any;
    servicedata1: any[];
    gb: any;
    new2: number;
    newsdata1: any[];
    content;
    news2: any;
    main_css: any;
    newsforme: boolean;


    constructor(private authservice: AuthService, private router: Router, private httpclient: HttpClient, private shared: SharedService) {
        this.tokenvalue = localStorage.getItem('auth-token');
    }

    ngOnInit() {
        this.authservice.hideshowheader(false);

        // if(localStorage.getItem('auth-token')==null)
        // {
        //   this.router.navigate(['login']);
        // }else{
        if (this.tokenvalue) {

            this.news(this.tokenvalue);
            this.news1(this.tokenvalue);

            // this.myservices(this.tokenvalue);
            //  this.fadeoutdata(this.tokenvalue);
        }

        // }


    }

    news(token) {
        var myarr = [];
        this.httpclient.get(this.api + "auth/news?token=" + token).subscribe((data: news) => {
            console.log(data)
            this.newsdata = data.news;

        }, (error) => {
            console.log(error);
        })


        //   this.httpclient.get(this.api+"auth/brand_landing?token="+token).subscribe((data:landing)=>{
        //     console.log(data)
        //     this.new1 = data.landing;
        //    },(error)=>{
        //      console.log(error);
        //  })
        //  this.servicedata = localStorage.getItem('servicedata');
        //  console.log( this.servicedata );
        //  console.log(' servicedata: ', JSON.parse( this.servicedata))
        //  console.log(this.servicedata['name'])
        //   // myarr.sort() =
        //  if(this.servicedata.name == "'Dräger'"){
        //   this.My =  myarr.push(this.servicedata.name);
        //   console.log(this.My)
        //  }

    }

    news1(token) {
        this.httpclient.get(this.api + "auth/brand_landing?token=" + token).subscribe((data: landing) => {
            console.log(data)
            this.newsdata1 = data.landing;


        }, (error) => {
            console.log(error);
        })

        this.shared.brandname.subscribe(
            servicedata => {
                if (servicedata == 'no drager') {
                    //  this.newsforme = true,
                    this.news2 = true;
                    this.content = false;
                } else if (servicedata == 'drager') {
                    // this.newsforme = false,

                    this.news2 = true;
                    this.content = true;
                } else if (servicedata == 'only drager') {
                    this.news2 = false;
                    this.content = true;
                    //  this.newsforme = false;

                }
            })
    }


    change_fout(id) {
        this.tokenvalue = localStorage.getItem('auth-token');
        this.httpclient.get(this.api + "auth/fout_insert?token=" + this.tokenvalue + "&slug=" + id).subscribe((data: foutdata) => {
            this.router.navigate(['/library/' + id]);
        }, (error) => {
            console.log(error);
        })
    }

}

export interface news {
    news: any[];
}

export interface servicesdata {
    service: any[];
}

export interface foutdata {
    status: any[];
}

// export interface servicedata
// {
//   my_service:any[];
// }

export interface landing {
    landing: any[];
}
