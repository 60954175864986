import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http'
import {environment} from '../../environments/environment';
import {Observable, BehaviorSubject} from "rxjs";
import {map} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    Header: HttpHeaders;
    api = environment.apiEndpoint

    constructor(private httpclient: HttpClient) {
        this.Header = new HttpHeaders();
        this.Header.append('Content-Type', 'applicaton/json');
    }

    signin(data): Observable<any> {
        return this.httpclient.post(this.api + "auth/login", data).pipe(map((data) => {
            let singindata = data
            console.log(singindata)
            return singindata;
        }))
    }

    isLoggedIn() {
        if (localStorage.getItem('auth-token')) {
            return true;
        } else {
            return false;
        }

    }

    autoLogin(id) {
        return this.httpclient.post(this.api + "auth/autologin?id=" + id, '').pipe(map((data) => {
            let singindata = data
            console.log(singindata)
            return singindata;
        }))
    }

    tophideheader = new BehaviorSubject(false);

    hideshowheader(showhideheader) {
        this.tophideheader.next(showhideheader);
    }

}

