import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth.service';
import {HttpClient} from '@angular/common/http'
import {SharedService} from '../service/shared.service';
import {environment} from 'src/environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
    selector: 'app-flow',
    templateUrl: './flow.component.html',
    styleUrls: ['./flow.component.css']
})
export class FlowComponent implements OnInit {
    service_slug: string;
    tokenvalue: string;
    allFlowdata: any;
    profile_datavalue: any[];
    imgurl = environment.imageurl;
    img: any;
    service_name: any;
    main_font: string;
    second_font: string;
    brandname: string;
    fontcss: any;
    color: any;
    fk_brand_id: string;

    constructor(public authservice: AuthService, private httpclient: HttpClient, private shareservice: SharedService, private domSanitizer: DomSanitizer, private router: Router) {

        this.service_slug = localStorage.getItem('library');
        this.tokenvalue = localStorage.getItem('auth-token');
    }

    ngOnInit() {
        this.fk_brand_id = localStorage.getItem("fk_brand_id");

        this.authservice.hideshowheader(false);

        this.getData()
        if (localStorage.getItem('auth-token')) {
            let token = localStorage.getItem('auth-token')
            this.getprofiledetails(token)
        }

    }

    getData() {
        // duplication (slug and service_slug) caused by unification of the input for Service Permission Middleware in the backend
        this.httpclient.get(this.shareservice.api + "auth/flow_builder?token=" + this.tokenvalue + "&slug=" + this.service_slug + "&service_slug=" + this.service_slug).subscribe((data: flow_data_interface) => {
            this.allFlowdata = data.flow_builder.cluster
            this.img = data.logo
            this.service_name = data.service_name
            this.brandname = data.name
            this.color = data.color
            ////check brand
            if (this.brandname == 'park') {
                this.main_font = "SilkaMono";
                this.second_font = "'Roboto', sans-serif";
            }
            if (this.brandname == 'grow') {
                this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brandname == 'coty') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";

            }
            if (this.brandname == 'lego') {
                this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
                this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brandname == "raymond") {
                this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brandname == "gsk") {
                this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
                this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brandname == "drager") {
                this.main_font = "draeger";
            }

            if (this.brandname == 'electrolux') {
                this.main_font = "'electrolux-sans-semibold'"
                this.second_font = "'electrolux-regular'";
            }

            this.fontcss = this.domSanitizer.bypassSecurityTrustHtml('<style>#flowbuilder h1,h2,h3,h4,h5,h6,p,table,tbody,tr,th{font-family:' + this.main_font + '} #flowbuilder .myborder {border-top: 1px solid #00ace5!important;} table:last-child{border-bottom: 1px solid #00ace5!important;} #flowbuilder hr {}</style>')
        }),
            (error) => {
                console.log(error)
            }
    }

    getprofiledetails(tokenvalue) {
        this.httpclient.get(this.shareservice.api + "auth/profiledata?token=" + tokenvalue).subscribe((data: profile) => {
            this.profile_datavalue = data.profile;
        }, (error) => {
            console.log(error);
        })
    }

    navigate(url) {
        this.router.navigate([url])
    }

    hoursicons(v) {
        var items = [];
        for (let i = 1; i <= v; i++) {
            items.push(i)
        }

        return items;
    }
}

export interface flow_data_interface {
    flow_builder: any,
    color: any,
    logo: any,
    name: any,
    service_name,
}

export interface profile {
    profile: any[];
}