import {Component, OnInit, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {Options} from 'ng5-slider';
import {AuthService} from '../service/auth.service';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {FormGroup, FormBuilder} from '@angular/forms';
import $ from 'jquery';

@Component({
    selector: 'app-final-evaluation',
    templateUrl: './final-evaluation.component.html',
    styleUrls: ['./final-evaluation.component.css']

})
export class FinalEvaluationComponent implements OnInit {
    value: number = 5;
    @Input() id: any[];
    @Input() main_color_value: any;
    @Input() page_change: any;
    @Input() heading_font_color: any;
    @Input() title_color: any;

    @Output() pageTrack = new EventEmitter()
    @Output() completechapter = new EventEmitter<any>()

    main_color: any;
    tokenvalue: string;
    api = environment.apiEndpoint;
    questiondata: any[] = [];
    pagedata: any;
    full_title: any;
    page_des: any;
    Q_header: any;
    Question: any;
    Q_required: this;
    page_content: any;
    imgurl = environment.imageurl;
    questions: any[] = []
    style: any;
    // reload:any;
    qaform: FormGroup;
    form: FormGroup;
    upload: boolean;
    user_slug: string;
    qdisabled: boolean = false;
    pageheader_image: any;
    maindivideline: any = "0";
    brandname: any;
    main_font: string;
    second_font: string;
    mainpage_css: any;
    color: any;
    bullet_back_src: string;
    bullet_position_top = 8;
    link_back_src: string;
    check_bac_src: string;
    chapter_id: any;
    service_id: string;
    module_id: string;
    cluster_id: string;

    service_enforce: any = 0;
    complete_chapter: any;
    font_color: string = "#000";
    electrolux_white_font: boolean = false;
    header_font_color: string;

    constructor(public authservice: AuthService, private httpclient: HttpClient, private domSanitizer: DomSanitizer, private qafb: FormBuilder) {
        this.user_slug = localStorage.getItem('user_slug')
    }

    ngOnInit() {
        // this.authservice.hideshowheader(false);
        if (this.id) {
            // this.getQuestionData(this.id);
        }
        this.main_color = this.main_color_value

    }


    getQuestionData(id) {
        this.electrolux_white_font = false
        this.tokenvalue = localStorage.getItem('auth-token');
        this.questions = [];
        this.qdisabled = false;
        this.httpclient.get(this.api + "auth/pagesingledata?id=" + id + "&token=" + this.tokenvalue).subscribe((data: question) => {
            this.questiondata = data.differentpage;
            this.pagedata = data.page;
            this.full_title = this.pagedata.page_title
            this.page_des = this.pagedata.page_description
            this.pageheader_image = this.pagedata.header_thumbnail
            this.chapter_id = this.pagedata.fk_chapter_id
            this.brandname = this.pagedata['brandname'];
            this.color = this.pagedata['chaptercolor'];
            this.service_id = this.pagedata.fk_service_id
            this.module_id = this.pagedata.fk_module_id
            this.cluster_id = this.pagedata.fk_cluster_id
            this.header_font_color = this.title_color ? this.title_color : 'white';

            if (localStorage.getItem('service_enforce') == '1') {
                this.complete_chapter = this.pagedata.complete_chapter
                this.service_enforce = localStorage.getItem('service_enforce')
                this.completechapter.emit(this.complete_chapter)

            }

            if (this.brandname == 'park') {
                this.main_font = "SilkaMono";
                this.second_font = "'Roboto', sans-serif";
                this.bullet_position_top = 7;
                this.header_font_color = 'black';
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-blue.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-green.svg"
                }
                if (this.color == 'yellow') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-yellow.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-yellow.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-yellow.svg"
                }
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-red.svg"
                }
                if (this.color == 'gray') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-gray.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-gray.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-gray.svg"
                }
            }

            if (this.brandname == 'grow') {
                this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'warm red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-warm-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-warm-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-warm-red.svg"
                }

                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-blue.svg"
                }
                if (this.color == 'purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-purple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-purple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-purple.svg"
                }
                if (this.color == 'orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-orange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-orange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-orange.svg"
                }
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-red.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-green.svg"
                }
                if (this.color == 'aqua') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-aqua.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-aqua.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-aqua.svg"
                }
            }

            if (this.brandname == 'draeger') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-draeger-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-draeger-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-draeger-red.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-draeger-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-draeger-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-draeger-green.svg"
                }
                if (this.color == 'light-blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-draeger-light-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-draeger-light-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-draeger-light-blue.svg"
                }
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-draeger-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-draeger-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-draeger-blue.svg"
                }
                if (this.color == 'orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-orange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-orange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-orange.svg"
                }


            }
            if (this.brandname == 'coty') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-purple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-purple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-purple.svg";
                    this.font_color = '#fff';
                }
                if (this.color == 'yellow') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-yellow.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-yellow.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-yellow.svg"
                }
                if (this.color == 'orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-orange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-orange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-orange.svg"
                }
                if (this.color == 'magenta') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-magenta.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-magenta.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-magenta.svg"
                }
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-red.svg"
                }
                if (this.color == 'light purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-lightpurple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-lightpurple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-lightpurple.svg"
                }
                if (this.color == 'dark blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-darkblue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-darkblue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-darkblue.svg";
                    this.font_color = '#fff';
                }
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-blue.svg"
                }
                if (this.color == 'aqua') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-aqua.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-aqua.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-aqua.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-green.svg"
                }
            }
            if (this.brandname == 'lego') {
                this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
                this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.bullet_position_top = 3;
                if (this.color == 'medium lilac') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-lilac.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-lilac.svg";
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Lego/check-lego-lilac.svg";
                }
                if (this.color == 'bright blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-blue.svg";
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Lego/check-lego-blue.svg";
                }
                if (this.color == 'dark green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-green.svg";
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Lego/check-lego-green.svg";
                }
                if (this.color == 'bright red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-red.svg";
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Lego/check-lego-red.svg";
                }
            }
            if (this.brandname == "raymond") {
                this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Raymond/bullet-raymond-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Raymond/external-raymond-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Raymond/check-raymond-red.svg";
                }
            }
            if (this.brandname == "gsk") {
                this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
                this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-orange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-orange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-orange.svg"
                }
                if (this.color == 'light orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-lightorange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-lightorange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-lightorange.svg"
                }
                if (this.color == 'aqua') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-aqua.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-aqua.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-aqua.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-green.svg"
                }
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-blue.svg"
                }
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-red.svg"
                }
                if (this.color == 'purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-purple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-purple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-purple.svg"
                }
                if (this.color == 'brown') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-brown.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-brown.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-brown.svg";
                    this.font_color = '#fff';

                }
                if (this.color == 'beige') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-beige.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-beige.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-beige.svg"
                }
                if (this.color == 'light') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-light.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-light.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-light.svg"
                }
            }

            if (this.brandname == 'drager') {
                this.main_font = "'draeger'"
                this.second_font = "'draeger2'";
                console.log(this.color)
                if (this.color == 'purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-purple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-purple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-purple.svg";


                }
                if (this.color == 'magenta') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-magenta.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-magenta.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-magenta.svg";

                }
                if (this.color == 'spruce') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-spruce.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-spruce.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-spruce.svg";


                }
                if (this.color == 'ocean') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-ocean-new.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-ocean.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-ocean.svg";

                }
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-blue.svg";


                }
                if (this.color == 'rose') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-rose.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-rose.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-rose.svg";

                }
                if (this.color == 'lime') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-lime.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-lime.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-lime.svg";


                }
                if (this.color == 'coral') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-coral.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-coral.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-coral.svg";


                }

            }

            if (this.brandname == 'electrolux') {
                this.main_font = "'electrolux-sans-semibold'"
                this.second_font = "'electrolux-regular'";
                console.log(this.color)
                if (this.color == 'blue') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-blue.svg";
                }
                if (this.color == 'dark blue') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-dark-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-dark-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-dark-blue.svg";
                }
                if (this.color == 'gray') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-gray.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-gray.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-gray.svg";
                }
                if (this.color == 'brown') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-brown.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-brown.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-brown.svg";
                }
                if (this.color == 'green') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-green.svg";
                }
                if (this.color == 'wine') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-wine.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-wine.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-wine.svg";
                }
                if (this.color == 'mustard') {
                    this.electrolux_white_font = false
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-mustard.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-mustard.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-mustard.svg";
                }
            }


            // this.mainpage_css = this.domSanitizer.bypassSecurityTrustHtml('<style>#mainpage h1,h2,h3,h4,h5,h6{color:' + this.font_color + '} #mainpage h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + '} #mainpage ul {list-style-type: none;} #mainpage .emphasized{color:' + this.heading_font_color + '} #mainpage ul,li,p,strong,{font-family:' + this.second_font + '} #mainpage ul.list { width:auto; } #mainpage ul.list li { padding:0 0 0 25px ; background:url("' + this.bullet_back_src + '") 0 8px no-repeat; list-style-type: none ; } a.external { display: block;color:' + this.heading_font_color + '!important; line-height: 25px;float: left;background: url("' + this.link_back_src + '") 0 8px no-repeat !important;padding: 0 0 7px 21px !important; } ul.link-list li {width: 100%;overflow: hidden;}  ul.link-list li a { border-bottom: 0 !important;} ul.link-list li { width: 100%;overflow: hidden;} #mainpage ul.check-list { width:auto; } #mainpage ul.check-list li { padding:0 0 0 25px !important; background:url("' + this.check_bac_src + '") 0 3px no-repeat; list-style-type: none ; } #mainpage ul.small-ordered-list .ordered-number { color:' + this.font_color + ';font-family:' + this.main_font + '!important}  #mainpage ul.ordered-list .ordered-number {color:#black;font-family:' + this.main_font + '!important}  #mainpage p,strong,div,strong,label {color:' + this.font_color + '!important} #mainpage a.anchor-tag{color:' + this.heading_font_color + '!important;border-bottom: 1px dotted ' + this.heading_font_color + ' !important }</style>')
            this.mainpage_css = this.domSanitizer.bypassSecurityTrustHtml('<style>#mainpage h1,h2,h3,h4,h5,h6{color:' + this.font_color + '} #mainpage h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + '} #mainpage ul {list-style-type: none;} #mainpage .emphasized{color:' + this.heading_font_color + '} #mainpage ul,li,p,strong,{font-family:' + this.second_font + '} #mainpage ul.list { width:auto; } #mainpage ul.list li { padding:0 0 0 25px ; background:url("' + this.bullet_back_src + '") 0 ' + this.bullet_position_top + 'px no-repeat; list-style-type: none ; } a.external { display: block;color:' + this.heading_font_color + '!important; line-height: 25px;float: left;background: url("' + this.link_back_src + '") 0 8px no-repeat !important;padding: 0 0 7px 21px !important; } ul.link-list li {width: 100%;overflow: hidden;}  ul.link-list li a { border-bottom: 0 !important;} ul.link-list li { width: 100%;overflow: hidden;} #mainpage ul.check-list { width:auto; } #mainpage ul.check-list li { padding:0 0 0 25px !important; background:url("' + this.check_bac_src + '") 0 3px no-repeat; list-style-type: none ; } #mainpage ul.small-ordered-list .ordered-number { color:' + this.font_color + ';font-family:' + this.main_font + '!important}  #mainpage ul.ordered-list .ordered-number {color:#black;font-family:' + this.main_font + '!important}  #mainpage p,strong,div,strong,label {color:' + this.font_color + '} #mainpage a.anchor-tag{color:' + this.heading_font_color + '!important;border-bottom: 1px dotted ' + this.heading_font_color + ' !important }</style>')
            this.page_content = this.domSanitizer.bypassSecurityTrustHtml(this.pagedata['introduction'] + '<style>#maincontent h1,h2,h3,h4,h5,h6{color:' + this.font_color + '} #maincontent h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + '} #maincontent ul {list-style-type: none;} #maincontent .emphasized{color:' + this.heading_font_color + '} #maincontent ul,li,p,strong{font-family:' + this.second_font + '} #maincontent ul.list { width:auto; } #maincontent ul.list li { padding:0 0 0 25px ; background:url("' + this.bullet_back_src + '") 0 ' + this.bullet_position_top + 'px no-repeat; list-style-type: none ; } a.external { display: block;color:' + this.heading_font_color + '!important; line-height: 25px;float: left;background: url("' + this.link_back_src + '") 0 8px no-repeat !important;padding: 0 0 7px 21px !important; } ul.link-list li {width: 100%;overflow: hidden;}  ul.link-list li a { border-bottom: 0 !important;} ul.link-list li { width: 100%;overflow: hidden;} #maincontent ul.check-list { width:auto; } #maincontent ul.check-list li { padding:0 0 0 25px !important; background:url("' + this.check_bac_src + '") 0 3px no-repeat; list-style-type: none ; } #maincontent ul.small-ordered-list .ordered-number { color:' + this.font_color + '!important;font-family:' + this.main_font + '!important}  #maincontent ul.ordered-list .ordered-number {color:' + this.font_color + '!important;font-family:' + this.main_font + '!important} #maincontent span.highlighted {color:' + this.heading_font_color + '} .question-header {color:' + this.font_color + '!important} #maincontent a.anchor-tag{color:' + this.heading_font_color + '!important } .main-bottom {border-bottom: 1px dotted ' + this.heading_font_color + '!important;} </style>');

            //question
            this.questions = this.questiondata['questions'];
            this.qdisabled = data.differentpage['check_page_save_or_not']
            this.maindivideline = this.pagedata['divide_question_bottom_page']


            if (localStorage.getItem('fname')) {
                $(document).ready(function () {
                    $('#firstname').text(localStorage.getItem('fname'))
                })

            }


        }, (error) => {
            console.log(error);
        });


    }

    tempSaveAnswer(type, id, value) {

        if (type == 'open_single_line') {
            value = $('#singleline' + id).val()
        }
        if (type == 'open_multiple_line') {
            value = $('#multilne' + id).val()
        }
        console.log(type, id, value)
        const fd = new FormData();

        fd.append('fk_page_id', this.questions['0'].pid)
        fd.append('chapter_id', this.chapter_id)
        fd.append('typeof_question', type)
        fd.append('fk_question_id', id)
        fd.append('answer_text', value)

        this.httpclient.post(this.api + 'auth/insert_page_question_answer_temp?token=' + this.tokenvalue + "&service_id=" + this.service_id + "&module_id=" + this.module_id + "&cluster_id=" + this.cluster_id, fd).subscribe((data) => {
            console.log(data);
        }), (error) => {
            console.log(error)
        }
    }


    submitQuestionAnswer() {
        //mcq , singleline , multilne , relevance , fivestep , tenstep
        //qd.pq_id , typeof_question
        let types = ['multiple_choice', 'open_single_line', 'open_multiple_line', 'relevance', 'five_step_slider', 'ten_step_slider']
        let count = String(this.questions.length)
        console.log(this.questions.length)

        const fd = new FormData()
        fd.append('user_slug', this.user_slug)
        fd.append('fk_page_id', this.questions['0'].pid)
        fd.append('total_count', count)
        fd.append('chapter_id', this.chapter_id)


        //to upload and check all
        for (let i = 0; i < this.questions.length; i++) {
            fd.append('id[]', this.questions[i].pq_id)
            //for mcq
            if (this.questions[i].typeof_question == 'multiple_choice') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val() == undefined) {
                        $('.' + 'mcqstar' + this.questions[i].pq_id).addClass('blink_me')
                        $('.reqtext').addClass('visible')
                    } else {
                        $('.' + 'mcqstar' + this.questions[i].pq_id).removeClass('blink_me')
                        $('.reqtext').removeClass('visible')
                        fd.append('fk_question_id', this.questions[i].pq_id)
                        fd.append('answer_text_' + this.questions[i].pq_id, $('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val())
                        fd.append('typeof_question_' + this.questions[i].pq_id, 'multiple_choice')
                    }
                } else {
                    fd.append('fk_question_id', this.questions[i].pq_id)
                    fd.append('answer_text_' + this.questions[i].pq_id, $('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val())
                    fd.append('typeof_question_' + this.questions[i].pq_id, 'multiple_choice')
                }
                console.log($('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val())
            }


            //for single line
            if (this.questions[i].typeof_question == 'open_single_line') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('#singleline' + this.questions[i].pq_id).val() == '') {
                        $('.' + 'singlelinestar' + this.questions[i].pq_id).addClass('blink_me')
                        $('.reqtext').addClass('visible')
                    } else {
                        $('.' + 'singlelinestar' + this.questions[i].pq_id).removeClass('blink_me')
                        $('.reqtext').removeClass('visible')

                        fd.append('fk_question_id', this.questions[i].pq_id)
                        fd.append('answer_text_' + this.questions[i].pq_id, $('#singleline' + this.questions[i].pq_id).val())
                        fd.append('typeof_question_' + this.questions[i].pq_id, 'open_single_line')
                    }
                } else {
                    fd.append('fk_question_id', this.questions[i].pq_id)
                    fd.append('answer_text_' + this.questions[i].pq_id, $('#singleline' + this.questions[i].pq_id).val())
                    fd.append('typeof_question_' + this.questions[i].pq_id, 'open_single_line')
                }
                console.log($('#singleline' + this.questions[i].pq_id).val())
            }

            //for multi line
            if (this.questions[i].typeof_question == 'open_multiple_line') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('#multilne' + this.questions[i].pq_id).val() == "") {
                        $('.' + 'multilnestar' + this.questions[i].pq_id).addClass('blink_me')
                        $('.reqtext').addClass('visible')
                    } else {
                        $('.' + 'multilnestar' + this.questions[i].pq_id).removeClass('blink_me')
                        $('.reqtext').removeClass('visible')

                        fd.append('fk_question_id', this.questions[i].pq_id)
                        fd.append('answer_text_' + this.questions[i].pq_id, $('#multilne' + this.questions[i].pq_id).val())
                        fd.append('typeof_question_' + this.questions[i].pq_id, 'open_multiple_line')
                    }
                } else {
                    fd.append('fk_question_id', this.questions[i].pq_id)
                    fd.append('answer_text_' + this.questions[i].pq_id, $('#multilne' + this.questions[i].pq_id).val())
                    fd.append('typeof_question_' + this.questions[i].pq_id, 'open_multiple_line')
                }
                console.log($('#multilne' + this.questions[i].pq_id).val())
            }

            //for relevance
            if (this.questions[i].typeof_question == 'relevance') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('input[name=' + 'relevance' + this.questions[i].pq_id + ']' + ':checked').val() == undefined) {
                        $('.' + 'relevancestar' + this.questions[i].pq_id).addClass('blink_me')
                        $('.reqtext').addClass('visible')
                    } else {
                        $('.' + 'relevancestar' + this.questions[i].pq_id).removeClass('blink_me')
                        $('.reqtext').removeClass('visible')

                        fd.append('fk_question_id', this.questions[i].pq_id)
                        fd.append('answer_text_' + this.questions[i].pq_id, $('input[name=' + 'relevance' + this.questions[i].pq_id + ']' + ':checked').val())
                        fd.append('typeof_question_' + this.questions[i].pq_id, 'relevance')
                    }
                } else {
                    fd.append('fk_question_id', this.questions[i].pq_id)
                    fd.append('answer_text_' + this.questions[i].pq_id, $('input[name=' + 'relevance' + this.questions[i].pq_id + ']' + ':checked').val())
                    fd.append('typeof_question_' + this.questions[i].pq_id, 'relevance')
                }
                console.log($('input[name=' + 'relevance' + this.questions[i].pq_id + ']' + ':checked').val())
            }

            //for five step
            if (this.questions[i].typeof_question == 'five_step_slider') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('input[name=' + 'fivestep' + this.questions[i].pq_id + ']' + ':checked').val() == undefined) {
                        $('.' + 'fivestepstar' + this.questions[i].pq_id).addClass('blink_me')
                        $('.reqtext').addClass('visible')
                    } else {
                        $('.' + 'fivestepstar' + this.questions[i].pq_id).removeClass('blink_me')
                        $('.reqtext').removeClass('visible')

                        fd.append('fk_question_id', this.questions[i].pq_id)
                        fd.append('answer_text_' + this.questions[i].pq_id, $('input[name=' + 'fivestep' + this.questions[i].pq_id + ']' + ':checked').val())
                        fd.append('typeof_question_' + this.questions[i].pq_id, 'five_step_slider')
                    }
                } else {
                    fd.append('fk_question_id', this.questions[i].pq_id)
                    fd.append('answer_text_' + this.questions[i].pq_id, $('input[name=' + 'fivestep' + this.questions[i].pq_id + ']' + ':checked').val())
                    fd.append('typeof_question_' + this.questions[i].pq_id, 'five_step_slider')
                }
                console.log($('input[name=' + 'fivestep' + this.questions[i].pq_id + ']' + ':checked').val())
            }

            //for ten step
            if (this.questions[i].typeof_question == 'ten_step_slider') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('input[name=' + 'tenstep' + this.questions[i].pq_id + ']' + ':checked').val() == undefined) {
                        $('.' + 'tenstepstar' + this.questions[i].pq_id).addClass('blink_me')
                        $('.reqtext').addClass('visible')
                    } else {
                        $('.' + 'tenstepstar' + this.questions[i].pq_id).removeClass('blink_me')
                        $('.reqtext').removeClass('visible')

                        fd.append('fk_question_id', this.questions[i].pq_id)
                        fd.append('answer_text_' + this.questions[i].pq_id, $('input[name=' + 'tenstep' + this.questions[i].pq_id + ']' + ':checked').val())
                        fd.append('typeof_question_' + this.questions[i].pq_id, 'ten_step_slider')

                    }
                } else {
                    fd.append('fk_question_id', this.questions[i].pq_id)
                    fd.append('answer_text_' + this.questions[i].pq_id, $('input[name=' + 'tenstep' + this.questions[i].pq_id + ']' + ':checked').val())
                    fd.append('typeof_question_' + this.questions[i].pq_id, 'ten_step_slider')

                }
                console.log($('input[name=' + 'tenstep' + this.questions[i].pq_id + ']' + ':checked').val())
            }


        }

        //to stop upload if valdation is not passeed
        for (let i = 0; i < this.questions.length; i++) {

            //for mcq
            if (this.questions[i].typeof_question == 'multiple_choice') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val() == undefined) {
                        this.upload = false;
                        return false;
                    } else {
                        this.upload = true;
                    }
                } else {
                    this.upload = true;
                }


            }

            //for single line
            if (this.questions[i].typeof_question == 'open_single_line') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('#singleline' + this.questions[i].pq_id).val() == '') {
                        this.upload = false;
                        return false;
                    } else {
                        this.upload = true;
                    }
                } else {
                    this.upload = true;
                }

            }

            //for multi line
            if (this.questions[i].typeof_question == 'open_multiple_line') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('#multilne' + this.questions[i].pq_id).val() == "") {
                        this.upload = false;
                        return false;
                    } else {
                        this.upload = true;
                    }
                } else {
                    this.upload = true;
                }

            }

            //for relevance
            if (this.questions[i].typeof_question == 'relevance') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('input[name=' + 'relevance' + this.questions[i].pq_id + ']' + ':checked').val() == undefined) {
                        this.upload = false;
                        return false;
                    } else {
                        this.upload = true;
                    }
                } else {
                    this.upload = true;
                }
            }

            //for five step
            if (this.questions[i].typeof_question == 'five_step_slider') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('input[name=' + 'fivestep' + this.questions[i].pq_id + ']' + ':checked').val() == undefined) {
                        this.upload = false;
                        return false;
                    } else {
                        this.upload = true;
                    }
                } else {
                    this.upload = true;
                }
            }

            //for ten step
            if (this.questions[i].typeof_question == 'ten_step_slider') {
                if (this.questions[i].question_isrequired == '1') {
                    if ($('input[name=' + 'tenstep' + this.questions[i].pq_id + ']' + ':checked').val() == undefined) {
                        this.upload = false;
                        return false;
                    } else {
                        this.upload = true;
                    }
                } else {
                    this.upload = true;
                }
            }


        }

        if (this.upload) {
            //mc_chbox
            for (let i = 0; i < this.questions.length; i++) {

                //for mcq
                if (this.questions[i].typeof_question == 'multiple_choice') {
                    console.log(this.questions[i].right_anser_ornot)
                    if (this.questions[i].right_anser_ornot == '0') {
                        //for first mcq
                        if (this.questions[i].mc_chbox == '1') {
                            if ($('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val() == this.questions[i].mc_chbox) {
                                $('#mcq1' + this.questions[i].pq_id).addClass('radio-right2')
                            } else {
                                $('#mcq1' + this.questions[i].pq_id).addClass('radio-right1')
                            }
                        }

                        //for second mcq
                        if (this.questions[i].mc_chbox == '2') {
                            if ($('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val() == this.questions[i].mc_chbox) {
                                $('#mcq2' + this.questions[i].pq_id).addClass('radio-right2')
                            } else {
                                $('#mcq2' + this.questions[i].pq_id).addClass('radio-right1')
                            }
                        }

                        //for third mcq
                        if (this.questions[i].mc_chbox == '3') {
                            if ($('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val() == this.questions[i].mc_chbox) {
                                $('#mcq3' + this.questions[i].pq_id).addClass('radio-right2')
                            } else {
                                $('#mcq3' + this.questions[i].pq_id).addClass('radio-right1')
                            }
                        }

                        //for fourth mcq
                        if (this.questions[i].mc_chbox == '4') {
                            if ($('input[name=' + 'mcq' + this.questions[i].pq_id + ']' + ':checked').val() == this.questions[i].mc_chbox) {
                                $('#mcq4' + this.questions[i].pq_id).addClass('radio-right2')
                            } else {
                                $('#mcq4' + this.questions[i].pq_id).addClass('radio-right1')
                            }
                        }
                    }


                }
            }

            this.httpclient.post(this.api + 'auth/insert_page_question_answer?token=' + this.tokenvalue + "&service_id=" + this.service_id + "&module_id=" + this.module_id + "&cluster_id=" + this.cluster_id, fd).subscribe((data: savequestion) => {
                $("#questionform :input").prop("disabled", true);
                $("#questionform :input:radio").prop("disabled", true);
                $('#submit' + this.questions['0'].pid).css('display', 'none')
                this.pageTrack.emit(true)
                if (this.service_enforce) {
                    this.completechapter.emit(data.complete_chapter)
                }
                alert('Your answers are saved.');
                console.log(data)
            })
        }

    }


    ngAfterViewInIt() {

    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes['page_change']) {
            console.log(this.page_change)
            console.log(this.id)
            if (this.page_change != undefined && (this.page_change == this.id)) {
                this.questions = [];
                this.questiondata = [];
                this.getQuestionData(this.id)
            }

        }
    }

}


export interface question {
    differentpage: any[];
    page: any[];
}

export interface question_formate {
    pid?: any;
    question_isrequired?: any
}

export interface savequestion {
    complete_chapter: any;
    message: any;
}




