import {Component, OnInit, SimpleChange} from '@angular/core';
import {AuthService} from '../service/auth.service';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {SharedService} from '../service/shared.service';
import $ from 'jquery';

@Component({
    selector: 'app-people',
    templateUrl: './people.component.html',
    styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {
    show_hide_profile: boolean = false;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    hide_on_ass: boolean = true;
    redirectURL: any;
    tabvalue = 'People'
    libraryvalue: string;
    token: string;
    check_people: any[];
    header: any;
    all_people: any[];
    user_slug: string;
    service_name: string;
    fk_brand_id: any;
    introduction: any;

    constructor(public authservice: AuthService, private shared: SharedService, private router: Router, private httpclient: HttpClient) {
        this.service_name = localStorage.getItem('service_name')
    }

    ngOnInit() {
        this.fk_brand_id = localStorage.getItem("fk_brand_id")
        this.authservice.hideshowheader(false);
        this.libraryvalue = localStorage.getItem('library');
        this.showhidepeople(this.libraryvalue);
        if (localStorage.getItem('user_slug')) {
            this.user_slug = localStorage.getItem('user_slug')
        }
        this.shared.peoplePage.subscribe(v => {
            this.tabvalue = v
            if (this.tabvalue == 'People') {
                this.show_hide_profile = false;
                this.hide_on_ass = true;
            }
            if (this.tabvalue == 'Profile') {
                this.show_hide_profile = true;
                this.hide_on_ass = true;
                setTimeout(() => {
                    $('#profile-tab').removeClass('active')
                    $('#profile').removeClass('show')
                    $('#profile').removeClass('active')
                    $('#home-tab').addClass('active')
                    $('#home').addClass('show')
                    $('#home').addClass('active')
                }, 100)


            }
            if (this.tabvalue == 'Assignments') {
                this.show_hide_profile = true;
                this.hide_on_ass = false;
                setTimeout(() => {
                    $('#home-tab').removeClass('active')
                    $('#home').removeClass('show')
                    $('#home').removeClass('active')
                    $('#profile-tab').addClass('active')
                    $('#profile').addClass('show')
                    $('#profile').addClass('active')
                }, 100)
            }
        })

    }

    showhidepeople(libraryvalue) {
        this.token = localStorage.getItem('auth-token');

        this.httpclient.get(this.api + "auth/peoplespage?token=" + this.token + "&service_slug=" + libraryvalue).subscribe((data: allvalues) => {
            this.check_people = data.service;
            this.all_people = data.peoples;

            this.header = this.check_people['header'];
            this.introduction = this.check_people['pepole_introduction'];

            if (this.check_people['people_allowed'] == 1) {
                this.router.navigate(['/news']);
            }
        }, (error) => {
            console.log(error);
        })
    }

    hideLeftProfile(v) {
        if (v == 'Profile') {
            this.hide_on_ass = true
        } else {
            this.hide_on_ass = false;
        }
    }

    shoeHideProfile() {
        this.show_hide_profile = !this.show_hide_profile
    }

    ngAfterContentInit() {
        this.shared.topbarpeople.subscribe(v => {
            // console.log("the value is "+v);
        })
    }

}

export interface allvalues {
    service: any[];
    peoples: any[];
}