import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {SharedService, user_accessInterface} from '../service/shared.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {environment} from 'src/environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-assignment-overview',
    templateUrl: './assignment-overview.component.html',
    styleUrls: ['./assignment-overview.component.css']
})
export class AssignmentOverviewComponent implements OnInit {
    assignments: any;
    token: string;
    serviceslug: string;
    imgurl = environment.imageurl;
    @Input() main_color_value: any
    @Input() page_title: any
    @Input() page_intro: any
    @Input() pageheader_image: any
    @Input() brand_name: any
    @Input() id: any;
    @Input() user_access: user_accessInterface;
    @Input() heading_font_color: any;
    @Input() title_color: any;
    @Input() is_service_note: any
    @Output() notes = new EventEmitter<any>()
    @Output() notesicon = new EventEmitter<any>()

    brandname: any
    color: any;
    notification: any = '0';
    main_font: string;
    second_font: string;
    // quotes_font: string;
    main_css: any;


    constructor(public authservice: AuthService, private http: HttpClient, private shared: SharedService, private domSanitizer: DomSanitizer) {
        this.token = localStorage.getItem('auth-token')
        this.serviceslug = localStorage.getItem('library')
    }

    ngOnInit() {
        //assignments overview
        this.color = this.main_color_value
        this.brandname = this.brand_name
        this.getAasignment()
        if (this.brandname == 'park') {
            this.main_font = "SilkaMono";
            this.second_font = "'Roboto', sans-serif";
            // this.quotes_font = "GuardianTextEgyptian";
        }
        if (this.brandname == 'grow') {
            this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
            this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
        }
        if (this.brandname == 'coty') {
            this.main_font = "'CotySans-Bold'";
            this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
        }
        if (this.brandname == 'lego') {
            this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
            this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
        }
        if (this.brandname == "raymond") {
            this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
            this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
        }
        if (this.brandname == "gsk") {
            this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
            this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
        }
        if (this.brand_name == 'Electrolux') {
            this.main_font = "'electrolux-sans-semibold'"
            this.second_font = "'electrolux-regular'";
        }
        {

        }
        this.main_css = this.domSanitizer.bypassSecurityTrustHtml('<style>#mainpage h1,h2,h3,h4,h5,h6{color:' + this.color + '} #mainpage h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + '} #mainpage .emphasized:{' + this.color + '} #mainpage ul,li,p,strong{font-family:' + this.second_font + '} </style>')
    }

    //assignments overview
    getAasignment() {

        this.http.get(this.shared.api + "auth/assignment_pages?token=" + this.token + "&service_slug=" + this.serviceslug).subscribe((data: assignment_interface) => {
            this.assignments = data.assignments
            this.notification = data.notification

            if (this.is_service_note == '0') {
                this.showNotes()
                this.getNotes()
            }

        })
    }

    showNotes() {
        this.notesicon.emit({
            isnoteallowed: this.is_service_note,
            notes_access: this.user_access.isaddnote,
            notification: this.notification
        })
    }


    getNotes() {
        this.notes.emit(this.id)
    }

}

export interface assignment_interface {
    assignments: any;
    notification?: any;
}
