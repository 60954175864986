import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import $ from 'jquery'
import {Router} from '@angular/router';
import {AuthService} from '../service/auth.service';
import {environment} from '../../environments/environment';
import {SharedService} from '../service/shared.service';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

    @Input() role: any;
    @Output() isPeople = new EventEmitter()

    icon_value = "keyboard_arrow_up";
    changeicon: boolean = true;
    topheader = false;
    hidebar = true;
    hideheader = true;
    libraryvalue: string;
    main_practicalities: any;
    main_evaluation: any;
    api = environment.apiEndpoint;
    token: string;
    main_service: any;
    main_notes: any;
    main_people: any;
    tokenvalue: string;
    slughvalue: any[];
    servicename: any;
    messagecount: number;
    service_flow: any;
    only_one_module: any = '0';
    link: any;
    fastflowdone: any;
    content: boolean;
    fk_brand_id: any;


    constructor(private route: Router, public authservice: AuthService, private router: Router, private shared: SharedService, private httpclient: HttpClient, public breakpointObserver: BreakpointObserver) {

    }

    ngOnInit() {
        this.breakpointObserver
            .observe(['(min-width:1024px) and (max-width:1920px)'])
            .subscribe((state) => {
                if (state.matches) {
                    $('.top-bar-library').removeClass('maxwidth-1920')
                } else {
                    $('.top-bar-library').addClass('maxwidth-1920')
                }
            });
        this.breakpointObserver
            .observe(['(min-width:768px) and (max-width:1023px)'])
            .subscribe((state) => {
                if (state.matches) {
                    $('.top-bar-library').removeClass('maxwidth-1920')
                } else {
                }
            });

        localStorage.setItem('keyboard_arrow', this.icon_value);

        this.token = localStorage.getItem('auth-token');
        this.libraryvalue = localStorage.getItem('library');
        this.only_one_module = localStorage.getItem('one_module')
        if (localStorage.getItem('link')) {
            this.link = localStorage.getItem('link')
        }
        this.shared.servicedone.subscribe(v => {
            this.fastflowdone = v
        })

        this.shared.message_count.subscribe(v => {
            this.messagecount = v
        })
        this.shared.service_slug.subscribe(v => {
            if (v != null) {
                this.libraryvalue = v;
                this.preperation_evaluation();
            } else {
                this.preperation_evaluation();
            }
        })


        this.getMessages()

        this.authservice.tophideheader.subscribe(v => {
            if (v) {
                this.topheader = v
            } else {
                this.topheader = false;
            }
        })
        if (this.router.url !== '/coty-livebook' || '/coty') {
            this.hidebar = true;
        } else {
            this.hidebar = false;
        }


        if (this.router.url == '/design-connect') {
            this.hideheader = false;
        } else {
            this.hideheader == true
        }


    }

    toChapterPage() {
        this.router.navigate(['/' + this.link])
    }

    preperation_evaluation() {

        this.httpclient.get(this.api + "auth/topbarmenu?token=" + this.token + "&service_slug=" + this.libraryvalue).subscribe((data: prep_evaluation) => {
            this.main_practicalities = data.practicalities;
            this.main_evaluation = data.evaluations;
            this.service_flow = data.service_flow

            this.main_service = data.service;
            this.main_notes = this.main_service['notes_allowed'];
            this.main_people = this.main_service['people_allowed'];
            this.isPeople.emit(this.main_people);
            this.fk_brand_id = this.main_service['fk_brand_id'];
            localStorage.setItem('fk_brand_id', this.fk_brand_id);

            //this code should be on if white top border needed
            // if (this.fk_brand_id == 6) {
            //   this.content = true;
            //   $("#library").css("top", "42px")
            //   $("#updarrow").css("top", "87px")
            //   $('#modulechaptername').css("top", "42px")

            //   setTimeout(() => {
            //     $('#chaptersection').css("padding-top", "132px")

            //   }, 500);


            // }


            this.servicename = this.main_service['name']
            console.log(this.main_service);
            localStorage.setItem('service_name', this.servicename)
            this.shared.getServiceName(this.servicename)
            localStorage.setItem('service_name', this.servicename)
            this.shared.topbarPeopleValue(this.main_people);
        })
    }


    toProfile(v) {
        if (v == 'People') {

            this.shared.peoplePageProAss('People')
            this.router.navigate(['/people']);
        }
    }

    personal_page() {
        this.tokenvalue = localStorage.getItem('auth-token');

        this.httpclient.get(this.api + "auth/profiledata?token=" + this.tokenvalue).subscribe((data: alldata) => {
            this.slughvalue = data.profile['slug'];
            this.router.navigate(['/profile-detail/' + this.slughvalue]);
        })

    }


    updownArrow() {
        this.changeicon = !this.changeicon;
        this.icon_value = this.changeicon ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
        localStorage.setItem('keyboard_arrow', this.icon_value);

        console.log(this.fk_brand_id)

        if (!this.changeicon) {

            //this code should be on if white top border needed
            // if (this.fk_brand_id == 6) {
            //   $("#updarrow").css("top", "45px")
            //   $('#modulechaptername').css("top", "40px")
            //   $('#chaptersection').css("padding-top", "87px")
            // }

            $('#updarrow').removeClass('up-down-arrow1')
            $('#updarrow').addClass('up-down-arrow2')
            $('#modulechaptername').removeClass('tm-45')
            // $('#modulechaptername').removeClass('position-fixed')
            $('#modulechaptername').addClass('tm-0')
            // $('#modulechaptername').addClass('position-relative')
            // $('#modulechaptername').removeClass('pr-70')
            $('#chaptersection').removeClass('tp-90')
            $('#chaptersection').addClass('tp-45')


        } else {
            //this code should be on if white top border needed
            // if (this.fk_brand_id == 6) {
            //   $("#updarrow").css("top", "87px")
            //   $('#modulechaptername').css("top", "42px")
            //   $('#chaptersection').css("padding-top", "132px")
            // }
            $('#updarrow').removeClass('up-down-arrow2')
            $('#updarrow').addClass('up-down-arrow1')
            $('#modulechaptername').removeClass('tm-0')
            // $('#modulechaptername').removeClass('position-relative')
            $('#modulechaptername').addClass('tm-45')
            // $('#modulechaptername').addClass('position-fixed')
            // $('#modulechaptername').addClass('pr-70')
            $('#chaptersection').removeClass('tp-45')
            $('#chaptersection').addClass('tp-90')


        }

    }


    signOut() {
        // localStorage.removeItem('auth-token');
        // localStorage.removeItem('user_slug');
        // localStorage.removeItem('library');
        // localStorage.removeItem('livebook');
        // localStorage.removeItem('modulestatus');
        // localStorage.removeItem('one_module');
        // localStorage.removeItem('chapter_name');
        // localStorage.removeItem('service_enforce');
        // localStorage.removeItem('service_name');
        // localStorage.removeItem('redirect');

        if (localStorage.getItem('iframe') == "1") {
            localStorage.clear();
            this.router.navigate(["/login-redirect"]);
        } else {
            localStorage.clear();
            this.router.navigate(["/login"]);
        }
    }

    //to get message notification data
    getMessages() {
        this.httpclient.get(this.shared.api + 'auth/view_message?token=' + this.token + "&service_slug=" + this.libraryvalue).subscribe((data: message_interface) => {
            console.log(data)
            // this.message_data=data.data
            this.shared.messageNotification(data.not_readcount)
        }),
            (error) => {
                console.log(error)
            }
    }


    libraryRedirect() {
        console.log('libvalue', this.fastflowdone);
        // this.router.navigate(['/library/' + this.libraryvalue])
        if (this.fastflowdone == null) {
            this.router.navigate(['/library/' + this.libraryvalue])
        }
        if (this.fastflowdone != null) {
            localStorage.setItem('from_library', 'true');
            this.router.navigate([this.fastflowdone])
        }
    }
}

export interface prep_evaluation {
    practicalities: any;
    evaluations: any;
    service_flow: any;
    service: any[];
}

export interface alldata {
    profile: any[];
}

export interface message_interface {
    data: any;
    not_readcount: any;
}