import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth.service';

@Component({
    selector: 'app-practicalities',
    templateUrl: './practicalities.component.html',
    styleUrls: ['./practicalities.component.css']
})
export class PracticalitiesComponent implements OnInit {

    constructor(public authservice: AuthService) {
    }

    ngOnInit() {
        this.authservice.hideshowheader(false);
    }

}
