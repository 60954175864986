import {NgModule} from '@angular/core';
import {AuthguardService} from './service/authguard.service';
import {Routes, RouterModule} from '@angular/router';
import {NewsComponent} from './news/news.component';
import {LoginComponent} from './login/login.component';
import {ProfileComponent} from './profile/profile.component';
import {ContactComponent} from './contact/contact.component';
import {LibraryComponent} from './library/library.component';
import {TopbarComponent} from './topbar/topbar.component';
import {PageheaderComponent} from './pageheader/pageheader.component';
import {DesignManagementComponent} from './design-management/design-management.component';
import {BooksComponent} from './books/books.component';
import {FinalEvaluationComponent} from './final-evaluation/final-evaluation.component';
import {FlowComponent} from './flow/flow.component';
import {PracticalitiesComponent} from './practicalities/practicalities.component';
import {PeopleComponent} from './people/people.component';
import {MyservicesComponent} from './myservices/myservices.component';

import {from} from 'rxjs';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NoteSummaryComponent} from './note-summary/note-summary.component';
import {ReflectionsComponent} from './reflections/reflections.component';
import {CotyComponent} from './coty/coty.component';

import {CotyDesignConnectComponent} from './coty/coty-design-connect/coty-design-connect.component';
import {ResetpasswordComponent} from './resetpassword/resetpassword.component';
import {SetpasswordComponent} from './setpassword/setpassword.component';
import {GrowOnethirdComponent} from './grow-onethird/grow-onethird.component';
import {ProfileDetailComponent} from './profile-detail/profile-detail.component';
import {MessagesComponent} from './messages/messages.component';
import {RefreshComponent} from './refresh/refresh.component';
import {PageOverviewComponent} from './page-overview/page-overview.component';

import {AutologinComponent} from './autologin/autologin.component';
import {LoginRedirectComponent} from './login-redirect/login-redirect.component';
import {ForgotPasswordRedirectComponent} from './forgot-password-redirect/forgot-password-redirect.component';
import {ResetPasswordRedirectComponent} from './reset-password-redirect/reset-password-redirect.component';
import {ForbiddenComponent} from "./forbidden/forbidden.component";


export const routes = RouterModule.forRoot([
    {
        path: 'page-overview/:module_id/:check_enforce/:page_id',
        component: PageOverviewComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'login-redirect',
        component: LoginRedirectComponent,
    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'forgot-password-redirect',
        component: ForgotPasswordRedirectComponent
    },
    {
        path: 'reset-password/:ps_token',
        component: ResetpasswordComponent
    },
    {
        path: 'reset-password-redirect/:ps_token',
        component: ResetPasswordRedirectComponent
    },
    {
        path: 'set-password/:ps_token',
        component: SetpasswordComponent
    },
    {
        path: 'news',
        component: NewsComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    {
        path: 'library/:m_id',
        component: LibraryComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'pageheader',
        component: PageheaderComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'topbar',
        component: TopbarComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'Module/:service_id/:module_id/:cluster_id/:chapter_slug/:active_at/:mtype',
        component: DesignManagementComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'livebook/:c_id/:mtype',
        component: BooksComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'finalEvaluation',
        component: FinalEvaluationComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'flow',
        component: FlowComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'practicalities',
        component: PracticalitiesComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'people',
        component: PeopleComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'profile-detail/:slug',
        component: ProfileDetailComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'note-summary',
        component: NoteSummaryComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'reflections',
        component: ReflectionsComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'coty',
        component: CotyComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'design-connect/:slug',
        component: CotyDesignConnectComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'grow-onethird',
        component: GrowOnethirdComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'messages',
        component: MessagesComponent,
        canActivate: [AuthguardService]
    },
    {
        path: 'autologin',
        component: AutologinComponent
    },
    {
        path: 'refresh',
        component: RefreshComponent
    },
    {
        path: 'permission-denied',
        component: ForbiddenComponent
    }
]);


export const routingComponents = [PageOverviewComponent, NoteSummaryComponent, NewsComponent, ProfileComponent, ContactComponent,
    LibraryComponent, PageheaderComponent, TopbarComponent, BooksComponent, FinalEvaluationComponent,
    FlowComponent, PracticalitiesComponent, PeopleComponent, DesignManagementComponent, MyservicesComponent,
    ForgotPasswordComponent, ReflectionsComponent, CotyComponent, CotyDesignConnectComponent, ProfileDetailComponent]
