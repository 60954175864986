import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from '../service/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {$} from 'jquery';
import {user_accessInterface} from '../service/shared.service';

@Component({
    selector: 'app-grow-fullpage',
    templateUrl: './grow-fullpage.component.html',
    styleUrls: ['./grow-fullpage.component.css']
})

export class GrowFullpageComponent implements OnInit {
    @Input() id: any[];
    @Input() main_color_value: any[];
    @Input() user_access: user_accessInterface;
    @Input() heading_font_color: any;
    @Input() title_color: any;
    @Output() completechapter = new EventEmitter<any>()
    @Output() page_id = new EventEmitter<any>()

    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    fullpagedata: any;
    tokenvalue: string;
    pagedata: any;
    color: any;
    brandname: any;
    full_title: any;
    full_description: any;
    imagedata: any;
    main_color: any;
    pageheader_image: any;
    main_font: string;
    second_font: string;
    service_enforce: any = 0;
    complete_chapter: any;


    constructor(public authservice: AuthService, private ar: ActivatedRoute, private httpclient: HttpClient) {
    }


    ngOnInit() {
        if (this.id) {
            this.getfullpagedata(this.id);
            this.main_color = this.main_color_value
        }
    }

    getfullpagedata(id) {
        this.tokenvalue = localStorage.getItem('auth-token');
        this.httpclient.get(this.api + "auth/pagesingledata?id=" + id + "&token=" + this.tokenvalue).subscribe((data: fullpagedata) => {
            this.fullpagedata = data.differentpage;
            this.pagedata = data.page;
            this.pageheader_image = this.pagedata.header_thumbnail
            this.full_title = this.pagedata['page_title'];
            this.full_description = this.pagedata['page_description'];
            this.imagedata = this.imgurl + 'storage/page_img/resize_' + this.fullpagedata['fullscreen_image'];
            this.color = this.pagedata['chaptercolor'];
            this.brandname = this.pagedata['brandname'];
            this.page_id.emit({pid: this.pagedata.id});
            if (localStorage.getItem('service_enforce') == '1') {
                this.complete_chapter = this.pagedata.complete_chapter
                this.service_enforce = localStorage.getItem('service_enforce')
                this.completechapter.emit(this.complete_chapter)

            }
            if (this.brandname == 'park') {
                this.main_font = "SilkaMono";
                this.second_font = "'Roboto', sans-serif";
            }
            if (this.brandname == 'grow') {
                this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brandname == 'coty') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";

            }
            if (this.brandname == 'lego') {
                this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
                this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brandname == "raymond") {
                this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
            }
            if (this.brandname == "gsk") {
                this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
                this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
            }

            if (this.brandname == 'drager') {
                this.main_font = "'draeger'"
                this.second_font = "'draeger2'";

            }

            if (this.brandname == 'electrolux') {
                this.main_font = "'electrolux-sans-semibold'"
                this.second_font = "'electrolux-regular'";
            }

        }, (error) => {
            console.log(error);
        })
    }
}

export interface fullpagedata {
    differentpage: any[];
    page: any[];
}