import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {user_accessInterface} from '../service/shared.service';


@Component({
    selector: 'app-grow-full-screen',
    templateUrl: './grow-full-screen.component.html',
    styleUrls: ['./grow-full-screen.component.css']
})
export class GrowFullScreenComponent implements OnInit {
    @Input() id: any[];
    @Input() main_color_value: any;
    @Input() user_access: user_accessInterface;
    @Input() heading_font_color: any;
    @Input() title_color: any;

    @Output() pageTrack = new EventEmitter<any>()
    @Output() completechapter = new EventEmitter<any>()
    @Output() page_id = new EventEmitter<any>()

    tokenvalue: string;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    reflectiondata: any;
    pagedata: any;
    main_color: any;
    full_title: any;
    color: any;
    brandname: any;
    page_description: any;
    introduction: any;
    show_notes: any = '0';

    reflection_textdata: any = ""
    pageheader_image: any;
    addreflection: any;
    main_font: string;
    second_font: string;
    bullet_back_src: string;
    bullet_position_top = 8;
    link_back_src: string;
    check_bac_src: string;
    chapter_id: any;
    service_id: any;
    module_id: any;
    cluster_id: any;
    service_enforce: any = 0;
    complete_chapter: any;
    user_actual_image: string;
    electrolux_white_font: boolean = false;
    header_font_color: string;

    constructor(private httpclient: HttpClient, private domSanitizer: DomSanitizer) {
        this.user_actual_image = localStorage.getItem('user_actual_image');
    }

    ngOnInit() {
        if (this.id) {
            this.getReflectionData(this.id);
        }
        this.main_color = this.main_color_value
        this.addreflection = this.user_access.isaddreflection

    }

    getReflectionData(id) {
        this.electrolux_white_font = false
        this.tokenvalue = localStorage.getItem('auth-token');
        this.httpclient.get(this.api + "auth/pagesingledata?id=" + id + "&token=" + this.tokenvalue).subscribe((data: reflection) => {
            this.reflectiondata = data.differentpage;
            this.pagedata = data.page;
            this.pageheader_image = this.pagedata.header_thumbnail
            this.full_title = this.pagedata['page_title'];
            this.color = this.pagedata['chaptercolor'];
            this.brandname = this.pagedata['brandname'];
            this.chapter_id = this.pagedata.fk_chapter_id;
            this.header_font_color = this.title_color ? this.title_color : 'white';

            this.service_id = this.pagedata.fk_service_id
            this.module_id = this.pagedata.fk_module_id
            this.cluster_id = this.pagedata.fk_cluster_id
            this.page_id.emit({pid: this.pagedata.id});
            //ol li::before margin-left :-1em changed to 0 em  and padding 0px
            if (localStorage.getItem('service_enforce') == '1') {
                this.complete_chapter = this.pagedata.complete_chapter
                this.service_enforce = localStorage.getItem('service_enforce')
                this.completechapter.emit(this.complete_chapter)

            }

            if (this.brandname == 'park') {
                this.main_font = "SilkaMono";
                this.second_font = "'Roboto', sans-serif";
                this.bullet_position_top = 7;
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-blue.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-green.svg"
                }
                if (this.color == 'yellow') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-yellow.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-yellow.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-yellow.svg"
                }
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-red.svg"
                }
                if (this.color == 'gray') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Park/bullet-park-academy-gray.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Park/external-park-academy-gray.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Park/check-park-academy-gray.svg"
                }
            }

            if (this.brandname == 'grow') {
                this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'warm red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-warm-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-warm-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-warm-red.svg"
                }

                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-blue.svg"
                }
                if (this.color == 'purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-purple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-purple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-purple.svg"
                }
                if (this.color == 'orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-orange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-orange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-orange.svg"
                }
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-red.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-green.svg"
                }
                if (this.color == 'aqua') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Grow/bullet-grow-aqua.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Grow/external-grow-aqua.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Grow/check-grow-aqua.svg"
                }
            }

            if (this.brandname == 'draeger') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-draeger-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-draeger-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-draeger-red.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-draeger-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-draeger-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-draeger-green.svg"
                }
                if (this.color == 'light-blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-draeger-light-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-draeger-light-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-draeger-light-blue.svg"
                }
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-draeger-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-draeger-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-draeger-blue.svg"
                }
                if (this.color == 'orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/draeger/bullet-orange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/draeger/external-orange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/draeger/check-orange.svg"
                }


            }
            if (this.brandname == 'coty') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-purple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-purple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-purple.svg"
                }
                if (this.color == 'yellow') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-yellow.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-yellow.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-yellow.svg"
                }
                if (this.color == 'orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-orange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-orange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-orange.svg"
                }
                if (this.color == 'magenta') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-magenta.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-magenta.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-magenta.svg"
                }
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-red.svg"
                }
                if (this.color == 'light purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-lightpurple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-lightpurple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-lightpurple.svg"
                }
                if (this.color == 'dark blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-darkblue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-darkblue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-darkblue.svg"
                }
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-blue.svg"
                }
                if (this.color == 'aqua') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-aqua.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-aqua.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-aqua.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Coty/bullet-coty-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Coty/external-coty-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Coty/check-coty-green.svg"
                }
            }
            if (this.brandname == 'lego') {
                this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
                this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.bullet_position_top = 3;
                if (this.color == 'medium lilac') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-lilac.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-lilac.svg";
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Lego/check-lego-lilac.svg";
                }
                if (this.color == 'bright blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-blue.svg";
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Lego/check-lego-blue.svg";
                }
                if (this.color == 'dark green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-green.svg";
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Lego/check-lego-green.svg";
                }
                if (this.color == 'bright red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Lego/bullet-lego-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Lego/external-lego-red.svg";
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Lego/check-lego-red.svg";
                }
            }
            if (this.brandname == "raymond") {
                this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Raymond/bullet-raymond-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Raymond/external-raymond-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Raymond/check-raymond-red.svg";
                }
            }
            if (this.brandname == "gsk") {
                this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
                this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
                if (this.color == 'orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-orange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-orange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-orange.svg"
                }
                if (this.color == 'light orange') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-lightorange.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-lightorange.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-lightorange.svg"
                }
                if (this.color == 'aqua') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-aqua.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-aqua.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-aqua.svg"
                }
                if (this.color == 'green') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-green.svg"
                }
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-blue.svg"
                }
                if (this.color == 'red') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-red.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-red.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-red.svg"
                }
                if (this.color == 'purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-purple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-purple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-purple.svg"
                }
                if (this.color == 'brown') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-brown.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-brown.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-brown.svg"
                }
                if (this.color == 'beige') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-beige.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-beige.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-beige.svg"
                }
                if (this.color == 'light') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Gsk/bullet-gsk-light.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Gsk/external-gsk-light.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Gsk/check-gsk-light.svg"
                }
            }

            if (this.brandname == 'drager') {
                this.main_font = "'draeger'"
                this.second_font = "'draeger2'";
                if (this.color == 'purple') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-purple.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-purple.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-purple.svg";


                }
                if (this.color == 'magenta') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-magenta.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-magenta.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-magenta.svg";

                }
                if (this.color == 'spruce') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-spruce.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-spruce.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-spruce.svg";


                }
                if (this.color == 'ocean') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-ocean-new.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-ocean.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-ocean.svg";

                }
                if (this.color == 'blue') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-blue.svg";


                }
                if (this.color == 'rose') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-rose.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-rose.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-rose.svg";

                }
                if (this.color == 'lime') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-lime.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-lime.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-lime.svg";


                }
                if (this.color == 'coral') {
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Draeger/bullet-draeger-coral.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Draeger/external-draeger-coral.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Draeger/check-draeger-coral.svg";


                }

            }

            if (this.brandname == 'electrolux') {
                this.main_font = "'electrolux-sans-semibold'"
                this.second_font = "'electrolux-regular'";
                console.log(this.color)
                if (this.color == 'blue') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-blue.svg";
                }
                if (this.color == 'dark blue') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-dark-blue.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-dark-blue.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-dark-blue.svg";
                }
                if (this.color == 'gray') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-gray.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-gray.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-gray.svg";
                }
                if (this.color == 'brown') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-brown.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-brown.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-brown.svg";
                }
                if (this.color == 'green') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-green.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-green.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-green.svg";
                }
                if (this.color == 'wine') {
                    this.electrolux_white_font = true
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-wine.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-wine.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-wine.svg";
                }
                if (this.color == 'mustard') {
                    this.electrolux_white_font = false
                    this.bullet_back_src = "./assets/img/snippet/BrandElements/Electrolux/bullet-electrolux-mustard.svg";
                    this.link_back_src = "./assets/img/snippet/BrandElements/Electrolux/external-electrolux-mustard.svg" //give it to <a.external> tag
                    this.check_bac_src = "./assets/img/snippet/BrandElements/Electrolux/check-electrolux-mustard.svg";
                }
            }

            this.page_description = this.domSanitizer.bypassSecurityTrustHtml(this.pagedata['page_description']);
            this.introduction = this.domSanitizer.bypassSecurityTrustHtml(this.reflectiondata['introduction'] + '<style>#title {font-family:' + this.main_font + '} #maincontent h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + '} #maincontent ul {list-style: none;} ol {list-style:none}  #maincontent ol  li::before {content: counter(li); color:black; display: inline-block; width: 1em; margin-left: 0em !important;font-size:32px } #maincontent ol li {counter-increment: li} #maincontent .emphasized {color:' + this.heading_font_color + '} #maincontent ul {font-size:24px} #maincontent ul,li,p,strong{font-family:' + this.second_font + '} #maincontent ul.list { width:auto; } #maincontent ul.list li { padding:0 0 0 25px ; background:url("' + this.bullet_back_src + '") 0 ' + this.bullet_position_top + 'px no-repeat; list-style-type: none ; } a.external { display: block;color:' + this.heading_font_color + '!important; line-height: 25px;float: left;background: url("' + this.link_back_src + '") 0 8px no-repeat !important;padding: 0 0 7px 21px !important; } ul.link-list li {width: 100%;overflow: hidden;}  ul.link-list li a { border-bottom: 0 !important;} ul.link-list li { width: 100%;overflow: hidden;} #maincontent ul.check-list { width:auto; } #maincontent ul.check-list li { padding:0 0 0 25px !important; background:url("' + this.check_bac_src + '") 0 3px no-repeat; list-style-type: none ; } #maincontent ul.small-ordered-list .ordered-number { color:' + this.heading_font_color + ';font-family:' + this.main_font + '!important}  #maincontent ul.ordered-list .ordered-number {color:' + this.heading_font_color + ';font-family:' + this.main_font + '!important} #maincontent p {padding:0px 0px 18px} #maincontent span.highlighted {color:' + this.heading_font_color + '} #maincontent a {color:' + this.heading_font_color + '!important; } .main-bottom {border-bottom: 1px dotted ' + this.heading_font_color + '!important;} </style>');
            this.show_notes = this.reflectiondata.reflection

            if (this.reflectiondata.reflection_text) {
                this.reflection_textdata = this.reflectiondata.reflection_text
                if (this.reflection_textdata.length != 0) {
                    this.sendReflectionData();
                }
            }
            if (this.show_notes == '0') {
                this.reflection_textdata = this.reflectiondata.reflection_text
                this.sendReflectionData();
            }
            console.log("abc", this);
            // if (this.fullpagedata.sc_background_is == "1") {
            //     this.whiteornot = this.heading_font_color;
            //     this.backcolor = "#ffffff";
            //     this.li_font_color = "#333333";
            // }
            // if (this.fullpagedata.sc_background_is == "0") {
            //     this.whiteornot = "#ffffff";
            //     this.backcolor = this.main_color;
            //     this.li_font_color = "#ffffff";
            // }


        }, (error) => {
            console.log(error);
        });
    }

    sendReflectionData() {

        this.httpclient.post(this.api + "auth/insert_reflection?token=" + this.tokenvalue + "&page_id=" + this.id + "&reflection_text=" + this.reflection_textdata + '&chapter_id=' + this.chapter_id + "&service_id=" + this.service_id + "&module_id=" + this.module_id + "&cluster_id=" + this.cluster_id + '&allow_student=' + this.show_notes, "").subscribe((data: reflectiontext) => {
            console.log(data)
            if (this.service_enforce) {
                this.completechapter.emit(data.complete_chapter)
            }
            this.pageTrack.emit(true)
        })
    }


    ngOnDestroy() {
        if (localStorage.getItem('service_enforce') == '1' && this.reflection_textdata != '') {
            this.sendReflectionData()
        }
    }
}


export interface reflection {
    differentpage: any[];
    page: any[];
}

export interface reflectiontext {
    complete_chapter: any;
    status: any;
    message: any;
}

