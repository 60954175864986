import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../service/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {supportsWebAnimations} from '@angular/animations/browser/src/render/web_animations/web_animations_driver';
import {pagedatadetails} from '../design-management/design-management.component';
import {environment} from 'src/environments/environment';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import $ from 'jquery';


@Component({
    selector: 'app-page-overview',
    templateUrl: './page-overview.component.html',
    styleUrls: ['./page-overview.component.css']
})
export class PageOverviewComponent implements OnInit {
    db: any;
    local1: any;
    exit_redirect: any;
    service_enforce: any;
    module_id: any;
    imgurl = environment.imageurl;


    slideConfig = {


        "slidesToShow": 5,
        "centerMode": true,
        "slidesToScroll": 1,
        "dots": false,
        "infinite": false,
        "swipeToSlide": true,

    };


    module_id2: any;
    api = environment.apiEndpoint;
    tokenvalue: string;
    check_enforce2: string;
    pagedata: any[] = [];
    data: any;
    fk_service_id: any;
    fk_module_id: any;
    fk_cluster_id: any;
    chapter_slug: any;
    id: any;
    typevalue: any;
    index: number = 0;
    i: any;
    page_description: any;

    @ViewChild('slickModal') slickModal: SlickCarouselComponent
    hoverIndex: any;
    page_title: any;
    module_name: string;
    hexacolor: any;
    color: any;
    fk_brand_id: string;
    library = false;
    livebook: string;
    service_enforce1: string;
    one_module: string;
    page_id: any;
    slick_index: any;


    constructor(private ar: ActivatedRoute, private auth: AuthService, private router: Router, private httpclient: HttpClient) {

    }

    ngOnInit() {
        // this.backtolibrary();
        this.livebook = localStorage.getItem("livebook")
        this.service_enforce1 = localStorage.getItem("service_enforce")
        this.one_module = localStorage.getItem("one_module")
        this.fk_brand_id = localStorage.getItem("fk_brand_id");

        $("body").css("overflow", "hidden")
        // if(this.fk_brand_id == "6"){

        //   $("#cont").removeClass("position-fixed1")

        // }

        this.auth.hideshowheader(false);

        this.ar.params.subscribe((params) => {
            this.tokenvalue = localStorage.getItem('auth-token');
            this.module_id2 = params['module_id'];
            this.check_enforce2 = params['check_enforce'];
            this.page_id = params['page_id'];
            // this.index;
            this.pagedata = [];

            this.pageOverviewData(this.module_id2, this.check_enforce2);

            // this.enter(this.i);
            // this.leave(this.i);
        })

        this.module_name = localStorage.getItem('module_name')
        console.log(this.module_name);

        // $(document).ready(function(){

        //   $("add").mouseenter(function(){
        //     console.log(111);
        //     $(".slidebox").stop(true,false).animate({
        //       "bottom":"174px",
        //     },500)
        //   })
        //   $(".product").mouseleave(function(){
        //     console.log(222);
        //     $(".slidebox").stop(true,false).animate({
        //       "bottom":"0"
        //     },500)
        //   })

        // // $("product").on({
        // //   mouseenter: function () {
        // //       $('.slidebox').css('imgbox', $(this).attr('img'));
        // //       //stuff to do on mouse enter
        // //   },
        // //   mouseleave: function () {
        // //       $('.slidebox').css('imgbox', 'default.png');
        // //       //stuff to do on mouse leave
        // //   } });
        // });

    }

    // backtolibrary(){

    //   this.livebook = localStorage.getItem("livebook")
    //   this.service_enforce1 = localStorage.getItem("service_enforce")
    //   this.one_module = localStorage.getItem("one_module")
    //   console.log(this.livebook)
    //   this.router.navigate(['/livebook' + '/'+this.livebook +'/'+ this.service_enforce1])

    // }


    enter(i) {


        // this.module_name = localStorage.getItem('module_name')
        console.log(111)
        this.hoverIndex = i;
        //  console.log(i);
        this.fk_service_id = this.pagedata[this.hoverIndex].fk_service_id;
        this.fk_module_id = this.pagedata[this.hoverIndex].fk_module_id;
        this.fk_cluster_id = this.pagedata[this.hoverIndex].fk_cluster_id;
        this.chapter_slug = this.pagedata[this.hoverIndex].chapter_slug;
        this.id = this.pagedata[this.hoverIndex].id;
        this.typevalue = this.pagedata[this.hoverIndex].typevalue;
        this.page_description = this.pagedata[this.hoverIndex].page_description;
        this.page_title = this.pagedata[this.hoverIndex].page_title;
        this.color = '#' + this.pagedata[this.hoverIndex].font_code;
        console.log(this.color)


        //  $('.product').css('overlay', $(this).attr('i'));
        // //       //stuff to do on mouse enter
        // //   },

        // $(".overlay").stop(true,false).animate({	

        //   "bottom":"0",
        // },500)


        //  this.hoverIndex = style.color = "red";


    }

    leave() {
        this.page_title = "";
        // this.module_name = "";
        this.page_description = "";


        //  console.log(i,fk,mi,ci,csi,id,ty,pd,pt);

        // // console.log(222);
        // $(".overlay").stop(true,false).animate({
        //   "bottom":"-175px",
        // },500)

    }


    pageOverviewData(m_id, ce_id) {
        this.httpclient.get(this.api + "auth/page_overview?token=" + this.tokenvalue + "&module_id=" + this.module_id2 + "&check_enforce=" + this.check_enforce2).subscribe((data: overviewpage) => {
                this.pagedata = data.all_pages;
                console.log(this.pagedata);
                for (let index = 0; index < this.pagedata.length; index++) {
                    if (this.pagedata[index]['id'] == this.page_id) {
                        this.slick_index = index;
                        setTimeout(() => {
                            $('#slick-button').trigger("click");
                        }, 100);
                        // console.log(this.slick_index);
                    } else {
                        // console.log('no',this.pagedata[index]);
                    }

                }

                $(document).ready(function () {
                    $('.slide').removeClass('ng-star-inserted')


                })
            }
        )
    };

    // afterChange($event) {

    //   console.log($event);
    //   console.log($event.currentSlide)
    //   for (let i=0;i < this.pagedata.length; i++) {
    //     if ($event.slick.$slides[i].className == 'slide act slick-slide slick-current slick-active') {
    //       this.index = i + 2;
    //       console.log(this.index)
    //       if(this.index != this.pagedata.length && this.index < this.pagedata.length)
    //       {
    //         this.fk_service_id = this.pagedata[this.index].fk_service_id;
    //         this.fk_module_id = this.pagedata[this.index].fk_module_id;
    //         this.fk_cluster_id = this.pagedata[this.index].fk_cluster_id;
    //         this.chapter_slug = this.pagedata[this.index].chapter_slug;
    //         this.id = this.pagedata[this.index].id;
    //         this.typevalue = this.pagedata[this.index].typevalue;
    //         console.log(this.fk_service_id,this.fk_module_id,this.fk_cluster_id,this.chapter_slug,this.id,this.typevalue)
    //       }

    //       if (this.index == this.pagedata.length) {
    //         this.index = 0;
    //         this.fk_service_id = this.pagedata[this.index].fk_service_id;
    //         this.fk_module_id = this.pagedata[this.index].fk_module_id;
    //         this.fk_cluster_id = this.pagedata[this.index].fk_cluster_id;
    //         this.chapter_slug = this.pagedata[this.index].chapter_slug;
    //         this.id = this.pagedata[this.index].id;
    //         this.typevalue = this.pagedata[this.index].typevalue;
    //         // console.log(this.index, this.pagedata.length)
    //         console.log(this.fk_service_id,this.fk_module_id,this.fk_cluster_id,this.chapter_slug,this.id,this.typevalue)

    //       }
    //       if (this.index > this.pagedata.length) {
    //         this.index = 1;
    //         this.fk_service_id = this.pagedata[this.index].fk_service_id;
    //         this.fk_module_id = this.pagedata[this.index].fk_module_id;
    //         this.fk_cluster_id = this.pagedata[this.index].fk_cluster_id;
    //         this.chapter_slug = this.pagedata[this.index].chapter_slug;
    //         this.id = this.pagedata[this.index].id;
    //         this.typevalue = this.pagedata[this.index].typevalue;
    //         // console.log(this.index, this.pagedata.length)
    //         console.log(this.fk_service_id,this.fk_module_id,this.fk_cluster_id,this.chapter_slug,this.id,this.typevalue)

    //       }


    //     }
    //   }

    // }


    // slickInit($event) {
    //    console.log($event)
    //   for (let i = 0; i < this.pagedata.length; i++) {
    //     if ($event.slick.$slides[i].className == 'slide act slick-slide slick-current slick-active' ) {
    //       this.index = i + 2;
    //       this.fk_service_id = this.pagedata[this.index].fk_service_id;
    //       this.fk_module_id = this.pagedata[this.index].fk_module_id;
    //       this.fk_cluster_id = this.pagedata[this.index].fk_cluster_id;
    //       this.chapter_slug = this.pagedata[this.index].chapter_slug;
    //       this.id = this.pagedata[this.index].id;
    //       this.typevalue = this.pagedata[this.index].typevalue;
    //       console.log(this.fk_service_id,this.fk_module_id,this.fk_cluster_id,this.chapter_slug,this.id,this.typevalue)
    //     }


    //   }
    // }


    redirectToPage() {
        // console.log(this.fk_service_id,this.fk_module_id,this.fk_cluster_id,this.chapter_slug,this.id,this.typevalue)
        this.router.navigate(['/Module' + '/' + this.fk_service_id + '/' + this.fk_module_id + '/' + this.fk_cluster_id + '/' + this.chapter_slug + '/' + this.id + '/' + this.typevalue])
    }

    ngOnDestroy() {
        // this.slickModal.unslick();
    }
}


export interface overviewpage {
    all_pages: any[];
    all_pages_count: number;
    check_enforce: number;
    status: string;
}